@tailwind components;
@tailwind utilities;

@layer components {
	.privacy {
		@apply h-[500px] w-full relative;
		background-attachment: fixed;

		.privacy_content {
			.privacy_nav {
				@apply flex justify-between items-center px-48 py-5;

				img {
					@apply w-52;
				}
			}
			.privacy_title {
				@apply text-center text-white text-4xl font-bold pt-8;
			}
			.details {
				@apply lg:mx-[300px] mx-[20px] py-8 px-5 bg-white absolute top-[300px] rounded-2xl;

				p {
					@apply py-2;
				}
				h2 {
					@apply text-2xl font-bold py-2;
				}
			}
		}
	}
}
