@tailwind components;
@tailwind utilities;

@layer components {
	.dataStore {
		background-image: linear-gradient(
			to right bottom,
			#eefdf6,
			#e5f3f4,
			#d0e6f4,
			#e1f7f5,
			#eefdf6,
			#eefdf6
		);

		.container {
			@apply w-full max-w-7xl mx-auto px-4 py-8;

			.top__summary {
				@apply flex flex-col bg-white rounded-md shadow-lg overflow-hidden mt-20;

				h3 {
					@apply w-full py-2 bg-[#33FD9B] text-[#117446] text-center text-lg font-semibold;
				}

				p {
					@apply p-4 text-slate-600;
				}
			}

			.files__lines {
				@apply grid grid-cols-1 sm:grid-cols-1 gap-10 mt-20;

				.left__side {
					& > h2 {
						@apply text-xl font-semibold mb-10;
					}

					.upload__box {
						@apply mt-4 overflow-hidden rounded-lg shadow-xl;

						.top__side {
							@apply bg-[#33FD9B] flex items-center gap-4 px-4 py-3;

							label {
								@apply flex-shrink-0 text-[#117446] flex items-center justify-center cursor-pointer;

								.icon {
									@apply w-7 h-7;
								}
							}

							input {
								@apply flex-grow bg-transparent border-none ring-0;
							}
						}

						.generate__btn__container {
							@apply border border-emerald-400 rounded-lg overflow-hidden mx-4 mt-5;

							& > p {
								@apply bg-[#CDFDE6] text-[#117446] text-center text-lg font-semibold py-2;
							}

							.generate__btn__body {
								@apply flex flex-col items-center justify-center gap-4 py-8;

								// .icon {
								// 	@apply bg-[#33FD9B] text-[#117446] w-10 h-10 p-1.5 rounded-full;
								// }

								button {
									@apply bg-[#33FD9B] text-[#117446] hover:bg-[#CDFDE6] hover:text-[#117446] p-4 rounded-full;

									.icon {
										@apply w-7 h-7;
									}
								}

								p {
									@apply text-gray-500;
								}
							}
						}

						& > h4 {
							@apply px-4 font-semibold my-5;
						}

						.blocks {
							@apply mb-4 px-4 grid grid-cols-1 gap-y-4;

							.single__block {
								@apply grid grid-cols-12 gap-4 text-[#117446] items-center;

								label:first-child {
									@apply col-span-5;
								}

								label:nth-child(2) {
									@apply col-span-7;
								}

								label {
									@apply bg-white rounded-md flex items-center justify-center gap-2 py-2 shadow-md;
								}
							}
						}

						.selected__files {
							@apply px-4 my-3;

							.single__block {
								@apply cursor-pointer flex items-center gap-3;

								& > .icon {
									@apply text-[#117446] text-xl;
								}

								button {
									@apply flex-shrink-0 text-[#117446] flex items-center justify-center cursor-pointer;

									.icon {
										@apply w-7 h-7;
									}
								}

								&:not(:last-child) {
									@apply mb-2;
								}
							}
						}

						.upload__blocks {
							@apply mb-4 px-4 grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4;

							.single__block {
								@apply cursor-pointer py-4 flex flex-col items-center justify-center gap-1 bg-white hover:bg-[#33FD9B] text-[#117446] rounded-md;

								.icon {
									@apply text-xl;
								}
							}
						}
					}
				}

				.right__side {
					& > h2 {
						@apply text-xl font-semibold mb-10;
					}

					.recent__file {
						@apply rounded-lg shadow-xl overflow-hidden;

						.top__side {
							@apply bg-[#33FD9B] flex items-center justify-between px-4 py-3;

							.info {
								@apply flex-grow flex items-center gap-2;

								img {
									@apply w-10 h-10 rounded-full;
								}

								p {
									@apply text-[#117446] font-semibold;
								}
							}

							span {
								@apply flex-shrink-0 text-[#117446] text-sm;
							}
						}

						.main__summary {
							@apply mx-4 mt-4 border border-emerald-200 rounded-lg p-4 text-base;
						}

						& > h4 {
							@apply px-4 font-semibold my-5;
						}

						.btns__container {
							@apply mb-4 px-4 grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4;

							button {
								@apply py-4 flex flex-col items-center justify-center gap-1 bg-white hover:bg-[#33FD9B] text-[#117446] rounded-md;

								.icon {
									@apply text-xl;
								}
							}
						}
					}
				}
			}

			.allFiles__container {
				& > h2 {
					@apply text-xl font-semibold mt-20 mb-10;
				}

				.files__container {
					@apply grid grid-cols-1 sm:grid-cols-1 gap-5 sm:gap-10;

					.single__file {
						@apply rounded-lg shadow-xl overflow-hidden;

						.top__side {
							@apply bg-[#33FD9B] flex items-center justify-between px-4 py-3;

							.info {
								@apply flex-grow flex items-center gap-2;

								img {
									@apply w-10 h-10 rounded-full;
								}

								p {
									@apply text-[#117446] font-semibold;
								}
							}

							span {
								@apply flex-shrink-0 text-[#117446] text-sm;
							}
						}

						.main__summary {
							@apply mx-4 mt-4 border border-emerald-200 rounded-lg p-4 text-base;
						}

						& > h4 {
							@apply px-4 font-semibold my-5;
						}

						.btns__container {
							@apply mb-4 px-4 grid grid-cols-1 sm:grid-cols-3 gap-2 sm:gap-4;

							button {
								@apply py-2 flex flex-col items-center justify-center gap-1 bg-white hover:bg-[#33FD9B] text-[#117446] rounded-md;

								.icon {
									@apply text-xl;
								}
							}
						}
					}
				}
			}

			// .drop__files {
			// 	@apply my-20 w-full h-60 flex flex-col items-center justify-center rounded-lg border-4 border-dashed border-emerald-400 bg-white;

			// 	.icon {
			// 		@apply text-6xl mb-5;
			// 	}

			// 	p {
			// 		@apply text-lg font-semibold;

			// 		span {
			// 			@apply text-emerald-600;
			// 		}
			// 	}

			// 	& > span {
			// 		@apply text-gray-500;
			// 	}
			// }

			// .summary__container {
			// 	@apply mb-20 grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-20;

			// 	.summary__item {
			// 		@apply w-full flex flex-col rounded-lg border-2 border-emerald-400 p-5 bg-white;

			// 		.top {
			// 			@apply w-full flex justify-between items-end mb-5;

			// 			h4 {
			// 				@apply font-semibold;
			// 			}

			// 			.icon {
			// 				@apply text-2xl;
			// 			}
			// 		}

			// 		.body {
			// 			p {
			// 				@apply text-sm text-gray-600;
			// 			}
			// 		}
			// 	}
			// }

			// .recent__container,
			// .allFiles__container {
			// 	& > h2 {
			// 		@apply text-xl font-semibold mb-10;
			// 	}

			// 	.files__container {
			// 		@apply grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-20;

			// 		.file__item {
			// 			@apply w-full grid grid-cols-7 rounded-md overflow-hidden bg-white;
			// 			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			// 			.left {
			// 				@apply py-20 sm:py-0 col-span-7 sm:col-span-3 bg-[#A7FFC1] flex items-center justify-center;

			// 				.icon {
			// 					@apply text-7xl sm:text-6xl;
			// 				}

			// 				img {
			// 					@apply w-full h-full max-w-[7rem] max-h-[7rem] relative;
			// 				}
			// 			}

			// 			.right {
			// 				@apply col-span-7 sm:col-span-4 flex flex-col p-10 gap-y-5;

			// 				h4 {
			// 					@apply flex items-start gap-x-2;

			// 					span:first-child {
			// 						@apply flex-grow font-semibold;
			// 					}

			// 					span:last-child {
			// 						@apply mt-1 flex-shrink text-sm text-gray-500;
			// 					}
			// 				}

			// 				p {
			// 					@apply text-sm text-gray-600;
			// 				}

			// 				button {
			// 					@apply font-semibold hover:underline mr-auto;
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			// .allFiles__container {
			// 	@apply my-20;
			// }
		}
	}
}
