@tailwind components;
@tailwind utilities;

@layer components {
	.diet {
		@apply relative;
		.diet__title {
			h1 {
				@apply text-center text-2xl lg:text-4xl font-bold text-emerald-600 py-32 bg-emerald-50;
			}
		}
		.diet__content {
			@apply w-80 lg:w-1/2 mx-auto absolute top-[215px] left-0 right-0 bottom-0 rounded-lg;
			img {
				@apply rounded-lg;
			}
			.diet__paragraph {
				@apply pt-8 pb-16;
				p {
					@apply pt-8;
				}
				h1 {
					@apply text-center lg:text-left text-2xl lg:text-4xl font-bold text-emerald-600;
				}
				h2 {
					@apply text-center lg:text-left text-xl lg:text-2xl font-bold text-emerald-600;
				}
				h3 {
					@apply text-center lg:text-left pb-5 lg:pb-0 text-2xl font-bold text-emerald-600 pt-5;
				}
				ul {
					@apply pl-10;
				}
			}
			.tips__content {
				@apply lg:flex lg:flex-row-reverse flex-col gap-10;
				img {
					@apply w-[300px] h-[300px] object-cover;
				}
			}
		}
	}
}
