@tailwind components;
@tailwind utilities;

@layer components {
	.my__doctor {
		h1 {
			@apply text-center text-4xl text-emerald-600 font-bold py-32;
		}
	}
}
