@tailwind components;
@tailwind utilities;

@layer components {
	.problemRoute {
		@apply w-full mx-auto bg-emerald-100 py-8;

		.card {
			@apply flex justify-center items-center gap-4 flex-col sm:flex-row;

			.card_content {
				@apply w-[180px] h-[200px]  bg-white p-4 rounded-xl shadow  transition-all duration-300 ease-in-out transform hover:scale-110;
			}

			p {
				@apply text-center pt-2;
			}
			img {
				@apply rounded-xl;
			}
		}
	}
}
