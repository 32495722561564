@tailwind components;
@tailwind utilities;

@layer components {
	.patientPrescription {
		background-image: linear-gradient(
			to right bottom,
			#eefdf6,
			#e5f3f4,
			#d0e6f4,
			#e1f7f5,
			#eefdf6,
			#eefdf6
		);

		.container {
			@apply w-full max-w-7xl mx-auto px-4 py-8;

			.drop__files__container {
				@apply my-20 w-full h-60 grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-20;

				.drop__files {
					@apply w-full h-full flex flex-col items-center justify-center rounded-lg bg-white border-2 border-emerald-400;

					.icon {
						@apply text-6xl mb-5;
					}

					p {
						@apply text-lg font-semibold;

						span {
							@apply text-emerald-600;
						}
					}

					& > span {
						@apply text-gray-500;
					}
				}
			}

			.button__container {
				@apply mb-20 w-full grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-20;

				.block {
					@apply w-full flex justify-center;

					button {
						@apply w-full max-w-[17rem] py-3 rounded-xl text-emerald-700;
					}

					&:first-child {
						button {
							@apply bg-[#46fe9e] hover:bg-[#2beb88] text-xl font-semibold;
						}
					}

					&:last-child {
						button {
							@apply bg-white hover:bg-gray-100 text-xl font-semibold border-2 border-emerald-700;
						}
					}
				}
			}

			.red__block {
				@apply mb-20 w-full h-24 rounded-lg bg-red-600;
			}

			.medicines__container {
				@apply w-full p-5 bg-white overflow-x-auto;
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='13' ry='13' stroke='%2334d399FF' stroke-width='4' stroke-dasharray='10%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

				table {
					@apply w-full table-fixed overflow-hidden;

					thead {
						@apply text-emerald-700;

						th {
							@apply text-center font-medium pt-3 pb-5;
						}
					}

					tbody {
						@apply text-gray-700;

						tr {
							@apply border-b border-gray-200;

							td {
								@apply p-3;

								&:not(:has(.intake)) p {
									@apply py-1 border border-emerald-500 text-center;
								}

								.intake {
									@apply grid grid-cols-3 gap-3;

									.time {
										@apply grid grid-cols-4 border border-emerald-700;

										p {
											@apply text-center py-1;
										}

										p:first-child {
											@apply col-span-1 border-r border-emerald-700;
										}

										p:nth-child(2) {
											@apply col-span-2 border-r border-emerald-700;
										}

										p:last-child {
											@apply col-span-1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
