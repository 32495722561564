@tailwind components;
@tailwind utilities;

@layer components {
	.bloodDonation {
		@apply w-full max-w-5xl mx-auto px-5 sm:px-0 py-5 flex flex-col gap-5;

		& > h1 {
			@apply text-2xl text-center font-semibold text-slate-500;
		}

		.input__group {
			@apply w-full flex flex-col gap-y-1;

			label:first-of-type {
				@apply text-slate-600 pb-1;
			}

			input {
				@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
			}

			textarea {
				@apply w-full flex-grow py-2 px-3 resize-none rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
			}

			label:last-child {
				@apply w-full h-36 flex items-center justify-center rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300;

				.icon {
					@apply w-16 h-16 text-emerald-600;
				}
			}
		}

		.form__group {
			@apply w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-4 sm:gap-y-0;
		}

		.radio__input__group {
			@apply w-full flex flex-col gap-y-1;

			h4 {
				@apply text-slate-600 pb-1;
			}

			.radio__group {
				@apply w-full py-2 px-3 rounded-md border border-slate-300 grid grid-cols-4 sm:flex sm:items-center sm:justify-between;

				.radio__item {
					@apply flex items-center gap-x-2 sm:gap-x-3;

					& > label {
						@apply text-slate-600;
					}

					& > input[type='radio'] {
						@apply w-4 h-4 text-emerald-400 focus:ring-1 focus:ring-emerald-300 focus:outline-none;
					}
				}
			}

			&.first {
				.radio__group {
					@apply flex items-center justify-between;

					.radio__item {
						@apply sm:w-1/2;
					}
				}
			}
		}

		.btn-submit {
			@apply w-full mt-4 py-2 px-3 rounded-md border border-emerald-400 bg-emerald-400 text-white font-semibold hover:bg-emerald-500 transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-emerald-300 disabled:opacity-50;
		}
	}

	.donationRequest {
		@apply w-full max-w-3xl mx-auto px-5 sm:px-0 py-5;

		& > h1 {
			@apply text-2xl text-center font-semibold text-slate-500;
		}

		.request__details {
			@apply mt-8 w-full flex flex-col gap-5;

			p {
				@apply text-lg text-slate-600;

				span {
					@apply font-semibold text-slate-500;
				}
			}

			h2 {
				@apply text-2xl font-semibold text-slate-500 pt-5 text-center border-t-2 border-gray-300;
			}
		}

		.btn-accept {
			@apply w-full mt-4 py-2 px-3 rounded-md border border-emerald-400 bg-emerald-400 text-white font-semibold hover:bg-emerald-500 transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-emerald-300 disabled:opacity-50;
		}
	}

	.blood__modal__container {
		@apply w-full sm:min-w-[45rem] max-w-5xl mx-auto px-5 sm:px-0 flex flex-col gap-5;

		& > h1 {
			@apply text-2xl text-center font-semibold text-slate-500;
		}

		.input__group {
			@apply w-full flex flex-col gap-y-1;

			label:first-of-type {
				@apply text-slate-600 pb-1;
			}

			input {
				@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
			}

			textarea {
				@apply w-full flex-grow py-2 px-3 resize-none rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
			}

			label:last-child {
				@apply w-full h-36 flex items-center justify-center rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300;

				.icon {
					@apply w-16 h-16 text-emerald-600;
				}
			}
		}

		.form__group {
			@apply w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-x-10 gap-y-4 sm:gap-y-0;
		}

		.btn-accept {
			@apply w-full mt-4 py-2 px-3 rounded-md border border-emerald-400 bg-emerald-400 text-white font-semibold hover:bg-emerald-500 transition-all duration-200 focus:outline-none focus:ring-1 focus:ring-emerald-300 disabled:opacity-50;
		}
	}
}
