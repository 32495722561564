@tailwind components;
@tailwind utilities;

@layer components {
	.doctor__card {
		@apply w-full max-w-3xl mx-auto px-4 sm:px-6 py-6 border rounded-lg bg-white;
		box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);

		.top {
			@apply w-full flex gap-4;

			.left {
				@apply w-full max-w-[100px] flex-shrink-0;

				img {
					@apply w-full h-full max-h-[100px] object-cover rounded-lg border border-emerald-400;
				}
			}

			.right {
				@apply flex-1 grid grid-cols-2;

				.group {
					p {
						@apply text-sm text-gray-500;
					}

					h1 {
						@apply text-base font-semibold;
					}
				}
			}
		}

		.bottom {
			.group1 {
				@apply mt-4 w-full grid;
				grid-template-columns: 2fr 1fr;

				.group__item {
					p {
						@apply text-sm text-gray-500;
					}

					h1 {
						@apply text-base font-semibold;
					}
				}
			}

			.group {
				@apply mt-4 w-full grid grid-cols-2 sm:grid-cols-3;

				.group__item {
					p {
						@apply text-sm text-gray-500;
					}

					h1 {
						@apply text-lg font-semibold;

						&.stars {
							@apply mt-1 flex items-center gap-1;

							svg {
								@apply w-5 h-5 fill-yellow-400;
							}
						}
					}
				}
			}

			.specialties {
				@apply mt-4;

				p {
					@apply text-base text-gray-500;
				}

				h1 {
					@apply mt-1 flex items-center gap-2 flex-wrap;

					span {
						@apply bg-slate-500 text-white px-2 py-1 rounded text-sm;
					}
				}
			}

			.footer {
				@apply mt-4 flex gap-4 items-start sm:items-center sm:justify-between flex-col sm:flex-row;

				button {
					@apply flex items-center gap-2 px-4 py-2 rounded-lg bg-emerald-400 text-white text-sm font-semibold transition duration-300 ease-in-out;

					&:hover {
						@apply bg-emerald-500;
					}

					span {
						@apply text-white;
					}

					&.btn-book {
						@apply w-full max-w-[160px] justify-center;
					}
				}
			}
		}
	}
}
