@tailwind components;
@tailwind utilities;

@layer components {
	.myProfile {
		@apply w-full max-w-5xl mx-auto px-5 sm:px-0 flex flex-col items-center mt-12 sm:mt-0;

		& > h1 {
			@apply mt-8 text-2xl font-semibold text-slate-500;
		}

		.loading {
			svg {
				@apply w-12 h-12 text-emerald-400;
			}
		}

		.box {
			@apply w-full pb-5 pt-7 flex flex-col sm:flex-row gap-x-7 items-center sm:items-start;

			.left {
				@apply w-full max-w-[280px] pb-4 sm:pb-0 sm:border-r-2 px-5 sm:border-slate-300;

				.avatar {
					@apply w-full max-w-[200px] h-full max-h-[200px] mx-auto mb-4 rounded-full border-[4px] border-emerald-400 overflow-hidden flex items-center justify-center;

					img {
						@apply w-full h-full object-cover;
					}

					svg {
						@apply w-12 h-12 text-emerald-400;
					}
				}

				& > input[type='file'] {
					@apply w-full py-2 px-1 outline-none border-none;

					&::-webkit-file-upload-button {
						@apply mr-3 px-4 py-1 bg-white rounded-md outline-none border-none ring-1 ring-slate-400 text-slate-500;
					}
				}
			}

			.right {
				@apply w-full flex flex-col gap-y-5;

				.form__group {
					@apply w-full flex items-center sm:gap-x-5 flex-col sm:flex-row gap-y-4 sm:gap-y-0;

					.input__group {
						@apply w-full sm:w-1/2 flex flex-col gap-y-1;

						& > label {
							@apply text-slate-600 pb-1;
						}

						& > input {
							@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
						}
					}

					.qualification__list {
						@apply w-full flex items-center flex-wrap;

						.qualification__item {
							@apply w-full max-w-[280px] flex items-center gap-x-3 py-2 px-3 rounded-md border border-slate-300 mb-2;

							& > span {
								@apply text-slate-500;
							}

							& > .icon {
								@apply w-5 h-5 text-slate-500 cursor-pointer;
							}
						}
					}
				}
			}
		}

		.btn-save {
			@apply w-full max-w-[280px] py-2 px-3 bg-emerald-500/90 text-white text-lg rounded-md mt-5 mb-10 sm:mb-0 hover:bg-emerald-500 shadow-lg shadow-emerald-400/30 transition-all duration-300 ease-in-out;
		}
	}
}
