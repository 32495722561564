@tailwind components;
@tailwind utilities;

@layer components {
	.ePrescription {
		@apply w-full min-h-screen bg-[#fbfbfb] flex justify-center p-4 sm:p-10;

		.box {
			@apply w-full max-w-7xl mx-auto bg-white rounded-lg shadow-lg p-4 sm:p-10;

			.sec__header {
				@apply pl-0 sm:pl-10 text-[#15e584] text-2xl sm:text-3xl font-bold mb-5;

				&:not(:first-child) {
					@apply mt-10;
				}
			}

			.sec__one {
				@apply p-4 sm:p-5 w-full flex flex-col sm:flex-row items-start mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply w-32 h-32 flex-shrink-0  flex items-center justify-center;

					.cover {
						@apply w-full overflow-hidden rounded-lg;

						img {
							@apply w-full h-full object-cover;
						}
					}
				}

				.right {
					@apply w-full flex flex-col gap-2 overflow-hidden;

					& > label {
						@apply flex items-center w-full sm:w-auto;

						.icon {
							@apply fill-[#117446] w-4 h-4;
						}

						input {
							@apply border-none ring-0 text-[#117446] placeholder:text-[#117446]/50 font-medium;
						}
					}

					.disease {
						@apply w-full flex items-center flex-wrap;

						.add {
							@apply w-full flex items-center gap-2;

							img {
								@apply w-5 h-5;
							}

							input {
								@apply w-fit border-none ring-0 text-[#117446] placeholder:text-[#117446] px-0 py-0;
							}

							.icon {
								@apply w-[0.9rem] h-[0.9rem] fill-[#117446] ml-1;
							}
						}
					}

					.info {
						@apply mt-1 w-full flex items-center gap-2 flex-wrap;

						.item {
							@apply w-full sm:w-auto flex items-center gap-2 p-1 border-[#15e584] border-2 rounded-xl;

							p {
								@apply text-[#117446] text-sm py-1 px-2 rounded-lg;

								background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);
							}

							input {
								@apply w-full max-w-[4rem] border-none ring-0 text-[#117446] text-sm placeholder:text-[#117446] px-0 py-0;
							}
						}

						.addItem {
							@apply flex items-center gap-2;

							.icon {
								@apply w-9 h-9 p-1 bg-[#15fb9c] stroke-[#117446] rounded-xl cursor-pointer;

								&:last-child {
									@apply p-1.5;
								}
							}
						}
					}
				}
			}

			// .sec__one {
			// 	@apply p-4 sm:p-5 w-full flex items-start mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

			// 	.left {
			// 		@apply w-32 h-32 flex-shrink-0 bg-slate-300 flex items-center justify-center;

			// 		.cover {
			// 			@apply w-full overflow-hidden rounded-lg;

			// 			img {
			// 				@apply w-full h-full object-cover;
			// 			}
			// 		}
			// 	}

			// 	.right {
			// 		@apply w-full flex flex-col gap-2;

			// 		h3 {
			// 			@apply text-lg text-[#117446] font-medium;
			// 		}

			// 		.disease {
			// 			@apply w-full flex items-center flex-wrap;

			// 			.add {
			// 				@apply w-full flex items-center gap-2;

			// 				img {
			// 					@apply w-5 h-5;
			// 				}

			// 				p {
			// 					@apply text-[#117446] text-base;
			// 				}

			// 				.icon {
			// 					@apply w-[0.9rem] h-[0.9rem] fill-[#117446] ml-1;
			// 				}
			// 			}
			// 		}

			// 		.info {
			// 			@apply mt-1 w-full flex items-center gap-2 flex-wrap;

			// 			.item {
			// 				@apply flex items-center gap-2 p-1 border-[#15e584] border-2 rounded-xl;

			// 				p {
			// 					@apply text-[#117446] text-sm py-1 px-2 rounded-lg;

			// 					background: linear-gradient(
			// 						180deg,
			// 						#14ffa1 0%,
			// 						#15e584 100%
			// 					);
			// 				}

			// 				span {
			// 					@apply text-[#117446] text-base pr-2;
			// 				}
			// 			}

			// 			.addItem {
			// 				@apply flex items-center gap-2;

			// 				.icon {
			// 					@apply w-9 h-9 p-1 bg-[#15fb9c] stroke-[#117446] rounded-xl cursor-pointer;

			// 					&:last-child {
			// 						@apply p-1.5;
			// 					}
			// 				}
			// 			}
			// 		}
			// 	}
			// }

			.sec__empty {
				@apply p-4 sm:p-5 w-full flex justify-center mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				h1 {
					@apply text-[#117446] text-xl font-medium text-center py-5;
				}
			}

			.sec__two {
				@apply p-4 sm:p-5 w-full flex flex-col sm:flex-row items-start mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply flex-grow w-full grid grid-cols-1 sm:grid-cols-3 gap-4;

					.block {
						.block__content {
							@apply w-full flex flex-col items-center gap-2 p-2 border-[#15e584] border-2 rounded-xl;

							h3 {
								@apply w-full text-center text-[#117446] py-1 rounded-lg;

								background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

								input {
									@apply w-full bg-transparent px-0 py-0 ring-0 border-none placeholder:text-[#117446] text-[#117446] text-center;
								}
							}

							textarea {
								@apply w-full h-24 resize-y p-1 border-none ring-0;
							}
						}
					}
				}

				.right {
					@apply flex items-center gap-2;

					.icon {
						@apply w-9 h-9 p-1 bg-[#15fb9c] stroke-[#117446] rounded-xl cursor-pointer;

						&:last-child {
							@apply p-1.5;
						}
					}
				}
			}

			.sec__test {
				@apply p-4 sm:p-5 w-full flex flex-col sm:flex-row gap-y-5 sm:gap-y-0 divide-y-2 sm:divide-y-0 mb-5 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply w-full sm:max-w-[13rem] pr-3 h-full max-h-[20rem] flex flex-col gap-2 overflow-y-auto mr-2;

					.category {
						@apply py-2 w-full bg-[#d9fbeb] text-[#2c9263] text-sm px-1 rounded-xl;

						&.active {
							background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);
						}
					}

					&::-webkit-scrollbar {
						width: 0.35rem;
					}

					&::-webkit-scrollbar-thumb {
						background: #93c6ae;
					}
				}

				.right {
					@apply flex-grow w-full grid grid-cols-1 sm:grid-cols-2 gap-4 sm:pl-4 pt-2 sm:pt-0 sm:border-l-2 border-slate-300 divide-y-2 sm:divide-y-0 sm:divide-x-2;

					.tests {
						@apply w-full h-full max-h-[20rem] flex flex-col gap-2;

						.tests__container {
							@apply flex-grow w-full h-full flex flex-col divide-y-2 overflow-y-auto;

							label {
								@apply w-full flex items-center gap-2 py-2 cursor-pointer;

								input {
									@apply w-4 h-4 ring-[#15e584] text-[#15e584] rounded-full invisible;

									&:checked {
										@apply visible;
									}
								}

								span {
									@apply text-[#117446] text-sm;
								}
							}
						}

						.actions {
							@apply flex-shrink-0 w-full flex items-center justify-between gap-2;

							button {
								background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

								@apply px-4 py-2 text-[#117446] rounded-lg;
							}
						}
					}

					.overview {
						// @apply w-full flex flex-col sm:pl-4;

						// h1 {
						// 	@apply flex-shrink-0 w-full flex items-center gap-2 py-2;

						// 	span {
						// 		@apply text-[#117446] text-xl font-bold;
						// 	}
						// }

						// .folder__img {
						// 	@apply py-5 sm:py-0 flex-grow w-full h-full flex items-center justify-center;
						// }

						@apply w-full max-h-[20rem] sm:pl-4;

						& > h1 {
							@apply mt-4 sm:mt-0 text-[#15e584] text-xl font-bold;
						}

						.selectedTest {
							@apply pt-2 w-full flex flex-col divide-y-2 max-h-[18rem] overflow-y-auto;

							p {
								@apply py-2 text-[#117446] text-sm flex items-center justify-between;
							}

							&::-webkit-scrollbar {
								width: 0.35rem;
							}

							&::-webkit-scrollbar-thumb {
								background: #93c6ae;
							}
						}
					}
				}
			}

			.sec__two__sub {
				@apply w-full flex justify-end mt-4 mb-5;

				button {
					@apply px-4 py-2 flex items-center mr-5;

					span {
						@apply text-[#117446] text-lg pr-4;
					}

					.icon {
						@apply w-9 h-9 p-1.5 bg-[#15fb9c] fill-[#117446] rounded-xl cursor-pointer;
					}
				}
			}

			.ai__header {
				@apply pl-5 sm:pl-10 text-[#15e584] font-bold mb-5 inline-block text-center;

				img {
					@apply w-20 sm:w-[6.5rem] mx-auto;
				}

				p {
					@apply text-3xl mt-2 inline-block;
				}
			}

			.sec__three {
				@apply sm:px-4 w-full grid grid-cols-1 gap-5;
			}

			.sec__three__sub {
				@apply w-full flex justify-end mt-4 mb-5;

				button {
					@apply px-4 py-2 flex items-center mr-5;

					span {
						@apply text-[#117446] text-lg pr-4;
					}

					.icon {
						@apply w-9 h-9 p-2 bg-[#15fb9c] fill-[#117446] rounded-xl cursor-pointer;
					}
				}
			}

			.sec__four {
				@apply w-full flex flex-col sm:flex-row items-center gap-y-4 sm:gap-y-0 px-2 sm:px-8;

				.left {
					@apply flex-grow grid grid-cols-2 sm:grid-cols-3 gap-2 sm:gap-4;

					.block {
						@apply flex flex-col gap-2;

						label {
							@apply flex items-center text-[#15e584] fill-[#15e584] gap-2;
						}

						.input__field {
							@apply w-full flex items-center;

							.icon {
								@apply fill-[#117446] stroke-[#117446] w-5 h-5 text-xl;
							}

							input {
								@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1 text-sm sm:text-base;

								&::-webkit-file-upload-button {
									display: none;
								}
							}
						}

						&:last-child {
							.input__field {
								.icon {
									@apply sm:mr-2;
								}
							}
						}
					}
				}

				.right {
					@apply sm:pl-20 w-full sm:w-auto sm:flex-shrink-0 flex items-center gap-2;

					button {
						@apply w-full sm:w-auto px-8 py-2 bg-[#15fb9c] text-[#117446] text-lg font-semibold rounded-xl cursor-pointer;
					}
				}
			}
		}

		.customTestModal {
			@apply fixed top-0 left-0 w-full h-full hidden items-center justify-center bg-black/40 z-[9999] overflow-y-scroll;

			.modal__container {
				@apply relative w-full max-w-xl h-auto bg-[#fff] rounded-2xl p-5;

				.btn-closeModal {
					@apply absolute top-0 right-0 p-2 flex items-center justify-center cursor-pointer;

					.icon {
						@apply fill-[#15fb9c] w-8 h-8;
					}
				}

				& > h1 {
					@apply text-[#15e584] text-xl font-bold;
				}

				.input__field {
					@apply w-full flex items-center mt-1;

					.icon {
						@apply fill-[#117446] stroke-[#117446] w-4 h-4 text-xl;
					}

					input {
						@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1 text-sm sm:text-base;
					}
				}

				.actions {
					@apply flex items-center justify-between gap-2 mt-2;

					button {
						@apply px-4 py-2 text-[#117446] font-semibold rounded-md cursor-pointer;

						&:first-child {
							@apply bg-[#c0c0c0] text-base;
						}

						&:last-child {
							@apply bg-[#15e584] text-base;
						}
					}
				}
			}

			&.active {
				@apply flex;
			}
		}
	}
}
