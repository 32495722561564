@tailwind components;
@tailwind utilities;

@layer components {
	.dashboard {
		@apply w-full max-w-6xl mx-auto px-5 sm:px-0 mt-12 sm:mt-0;

		.box {
			@apply w-full py-5 flex flex-col sm:flex-row gap-x-7 gap-y-7 sm:gap-y-0;

			.left {
				@apply w-full sm:w-2/3;

				.topbar {
					@apply flex items-center justify-between flex-col sm:flex-row gap-y-2 sm:gap-y-0;

					& > h1 {
						@apply text-2xl font-semibold text-slate-500;
					}

					.search {
						@apply w-full h-10 max-w-[400px] flex items-center justify-between bg-white rounded-md focus-within:ring-1 focus-within:ring-emerald-300 border transition duration-300 ease-in-out;

						input {
							@apply w-full bg-transparent outline-none text-sm text-gray-500 border-none ring-0 tracking-wider;
						}

						.icon {
							@apply w-14 text-2xl;
						}

						&:focus-within {
							.icon {
								@apply stroke-emerald-500;
							}
						}
					}
				}

				.welcome {
					@apply mt-5 p-5 w-full bg-emerald-100/40 rounded-md border border-emerald-200 shadow-slate-400/20;

					& > h2 {
						@apply text-xl sm:text-2xl font-semibold text-slate-500;
					}

					& > h1 {
						@apply mt-2 text-2xl sm:text-4xl font-semibold text-slate-500;

						span {
							@apply text-emerald-500;
						}
					}

					p {
						@apply mt-2 text-lg text-slate-500;

						&:last-of-type {
							@apply mt-0;
						}
					}
				}

				.actions__btn {
					@apply mt-10 w-full grid grid-cols-1 sm:grid-cols-2 gap-5;

					.action {
						@apply w-full py-5 flex flex-col gap-2 items-center justify-center bg-white border-2 shadow-lg shadow-emerald-300/20 rounded-lg transition duration-300 ease-in-out;

						img {
							@apply w-16;
						}

						span {
							@apply text-lg font-semibold text-slate-500/80 tracking-wide transition duration-300 ease-in-out;
						}

						&:hover {
							@apply border-emerald-300;

							span {
								@apply text-emerald-500;
							}
						}
					}
				}
			}

			.right {
				@apply w-full sm:w-1/3;

				.avatar {
					@apply mt-5 w-full max-w-[10rem] mx-auto h-40 rounded-full border-4 border-emerald-300 flex items-center justify-center;

					.icon {
						@apply w-24 h-24 stroke-emerald-400;
					}
				}

				.balance {
					@apply mt-5 w-full flex flex-col items-center divide-y-2;

					p {
						@apply text-right text-xl py-1 font-semibold;

						span {
							@apply text-emerald-500;
						}
					}
				}

				.chart {
					@apply mt-4 w-full min-h-[300px] bg-slate-200 shadow rounded-lg;
				}
			}
		}
	}
}
