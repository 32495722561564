@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.aiAssistant {
		background-image: linear-gradient(
			to right bottom,
			#eefdf6,
			#e5f3f4,
			#d0e6f4,
			#e1f7f5,
			#eefdf6,
			#eefdf6
		);

		.container {
			@apply w-full max-w-7xl mx-auto px-4 py-8;

			.drop__files {
				@apply my-20 w-full h-60 flex flex-col items-center justify-center rounded-lg border-4 border-dashed border-emerald-400 bg-white;

				.icon {
					@apply text-6xl mb-5;
				}

				p {
					@apply text-lg font-semibold;

					span {
						@apply text-emerald-600;
					}
				}

				& > span {
					@apply text-gray-500;
				}
			}

			.generate__section {
				@apply p-4 rounded-lg shadow-xl grid grid-cols-12 gap-4;

				.file__preview {
					@apply col-span-12 sm:col-span-3 bg-white rounded-lg overflow-hidden;

					.top__side {
						@apply bg-[#33FD9B] flex items-center justify-between px-4 py-3;

						p {
							@apply text-[#117446] uppercase font-semibold;
						}

						span {
							@apply text-xs font-medium text-slate-600;
						}
					}

					.file__view {
						@apply w-full h-48 p-2 flex items-center justify-center;

						&.dummy img {
							@apply w-20 object-cover;
						}

						&:not(.dummy) {
							img {
								@apply w-full h-full object-cover;
							}
						}
					}
				}

				.generate__summary {
					@apply col-span-12 sm:col-span-5;
				}

				.generate__advice {
					@apply col-span-12 sm:col-span-4;
				}

				.generate__summary,
				.generate__advice {
					@apply bg-white rounded-lg overflow-hidden;

					.top__side {
						@apply bg-[#33FD9B] flex items-center justify-center px-4 py-3;

						p {
							@apply text-[#117446] uppercase font-semibold;
						}
					}

					.summary__body,
					.advice__body {
						@apply px-4 pb-3 h-48 flex items-center justify-center flex-col gap-y-3;

						.icon {
							@apply bg-[#33FD9B] text-[#117446] w-16 h-16 p-3 rounded-full;
						}

						p {
							@apply text-gray-500;
						}

						&:not(.dummy) {
							& > p {
								@apply h-48 overflow-y-auto;
							}
						}
					}
				}
			}

			.allFiles__container {
				@apply mt-20;

				& > h2 {
					@apply text-xl font-semibold mb-10;
				}

				.files__container {
					@apply grid grid-cols-1 gap-y-5 sm:gap-y-10;

					.generated__section {
						@apply p-4 rounded-lg shadow-xl grid grid-cols-12 gap-4;

						.file__preview {
							@apply col-span-12 sm:col-span-3 bg-white rounded-lg overflow-hidden;

							.top__side {
								@apply bg-[#33FD9B] flex items-center justify-between px-4 py-3;

								p {
									@apply text-[#117446] uppercase font-semibold;
								}

								span {
									@apply text-xs font-medium text-slate-600;
								}
							}

							.file__view {
								@apply w-full h-48 p-2 flex items-center justify-center;

								img {
									@apply w-full h-full object-cover;
								}

								button {
									@apply px-4 py-2 text-[#117446] border border-[#117446] rounded-md font-medium;
								}
							}
						}

						.generate__summary {
							@apply col-span-12 sm:col-span-5;
						}

						.generate__advice {
							@apply col-span-12 sm:col-span-4;
						}

						.generate__summary,
						.generate__advice {
							@apply bg-white rounded-lg overflow-hidden;

							.top__side {
								@apply bg-[#33FD9B] flex items-center justify-center px-4 py-3;

								p {
									@apply text-[#117446] uppercase font-semibold;
								}
							}

							.summary__body,
							.advice__body {
								@apply p-5 h-48;

								p {
									@apply h-48 overflow-y-auto text-gray-500;
								}
							}
						}
					}
				}
			}
		}
	}
}
