@tailwind components;
@tailwind utilities;

@layer components {
	.messagesPage {
		@apply px-4 sm:px-20 w-full h-[calc(100vh-72px)] flex items-center justify-center;

		.box {
			@apply mt-12 sm:mt-0 w-full h-[calc(100vh-80px)] sm:h-[calc(100vh-120px)] flex bg-emerald-100/70 rounded-lg shadow-lg py-5 pr-4 sm:pr-5 divide-x-2;

			.conversations {
				@apply w-full sm:w-1/3 h-full pl-5 hidden sm:flex flex-col overflow-auto;

				.conversation {
					@apply pl-2 pr-5 py-2 w-full flex items-center gap-5 cursor-pointer rounded-l-lg transition duration-300 ease-in-out;

					.image {
						@apply min-w-[3.5rem] min-h-[3.5rem] flex-shrink rounded-full overflow-hidden;

						img {
							@apply w-14 h-14 rounded-full object-cover;
						}
					}

					.body {
						@apply flex-grow py-2 flex flex-col justify-center overflow-hidden border-b-2 border-slate-300;

						.top {
							@apply flex items-center justify-between;

							.name {
								@apply text-lg font-semibold;
							}

							.time {
								@apply text-sm text-gray-500;
							}
						}

						p {
							@apply text-sm whitespace-nowrap overflow-hidden overflow-ellipsis;
						}
					}

					&.active {
						@apply bg-emerald-300;

						.body {
							@apply border-transparent;

							.name {
								@apply text-slate-900;
							}

							.time {
								@apply text-slate-900;
							}

							p {
								@apply text-slate-900;
							}
						}
					}

					&:hover {
						@apply bg-emerald-200;

						.body {
							@apply border-transparent;
						}
					}
				}

				&.active {
					@apply flex;
				}
			}

			/*----------- opened conversation -----------*/

			.opened-conversation {
				@apply w-full sm:w-2/3 h-full hidden sm:flex flex-col pl-4;

				.full-conversation {
					@apply h-[calc(100vh-6rem)] sm:h-[calc(100vh-10rem)] w-full flex flex-col;

					.topbar {
						@apply pb-4 border-b-2 border-slate-300 flex items-center justify-between;

						.left {
							@apply flex items-center gap-4;

							.image {
								@apply flex-shrink rounded-full overflow-hidden;

								img {
									@apply w-12 h-12 rounded-full object-cover;
								}
							}

							.info {
								@apply flex-grow flex flex-col;

								.name {
									@apply font-semibold text-slate-700 text-lg;
								}

								.status {
									@apply text-sm text-gray-500;
								}
							}
						}

						.right {
							@apply flex items-center pr-3;

							.video__call {
								@apply py-2 px-3 rounded-md shadow-lg shadow-emerald-400/30 bg-emerald-400;

								.icon {
									@apply text-3xl stroke-white;
								}
							}
						}
					}

					.messages {
						@apply flex-grow w-full py-4 pr-2 flex flex-col-reverse overflow-auto;

						.message {
							@apply w-full max-w-xl flex items-end gap-2 sm:gap-4 py-2;

							.image {
								@apply min-w-[2.25rem] min-h-[2.25rem] flex-shrink rounded-full overflow-hidden;

								img {
									@apply w-9 h-9 rounded-full object-cover;
								}
							}

							.body {
								@apply py-1 px-2 flex flex-col gap-1 bg-white;

								p {
									@apply text-slate-600 text-sm;
								}

								.time {
									@apply text-xs text-gray-400;
								}
							}

							&.left {
								@apply justify-start mr-auto pr-2;

								.body {
									@apply rounded-lg shadow-lg;
								}
							}

							&.right {
								@apply justify-end ml-auto pl-2;

								.body {
									@apply rounded-lg shadow-lg;

									.time {
										@apply text-right;
									}
								}
							}
						}
					}

					.footer {
						@apply w-full flex items-center gap-4 pt-4 border-t-2 border-slate-300;

						.icon {
							@apply flex-shrink text-2xl active:bg-emerald-400;
						}

						.message__input {
							@apply w-full min-w-[10rem] flex-grow bg-white border-none ring-0 rounded-lg;
						}
					}
				}

				&.active {
					@apply flex;
				}
			}
		}
	}
}
