@tailwind components;
@tailwind utilities;

@layer components {
	.notifications__page {
		@apply w-full max-w-4xl mx-auto px-5 sm:px-0 flex flex-col items-center mt-4 sm:mt-8;

		& > h1 {
			@apply text-xl font-semibold text-slate-600 mb-5;
		}

		.all__notifications {
			@apply w-full max-w-xl mx-auto flex flex-col items-center gap-2;

			.notification__link {
				@apply w-full px-4 py-2 hover:bg-slate-200 flex items-center rounded cursor-pointer;

				span {
					@apply flex-grow;
				}

				.icon {
					@apply flex-shrink-0 ml-4 w-6 h-6;
				}
			}
		}
	}
}
