@tailwind components;
@tailwind utilities;

@layer components {
	.searchDoctor {
		@apply w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 mt-14 sm:mt-0;

		.search__box {
			@apply w-full max-w-3xl mx-auto pt-8;

			.input__group {
				@apply w-full flex items-center bg-white rounded-lg px-5 border-2 ring-2 ring-transparent border-slate-200 focus-within:border-emerald-200 focus-within:ring-emerald-200 transition duration-300 ease-in-out;

				input {
					@apply w-full py-2 px-0 rounded-l-lg border-none ring-0 outline-none text-lg;
				}

				.icon {
					@apply text-2xl transition duration-300 ease-in-out;
				}

				&:focus-within {
					.icon {
						@apply fill-emerald-500;
					}
				}
			}
		}

		.loading__svg {
			@apply flex items-center justify-center;

			svg {
				@apply w-20 h-20;
			}
		}

		.search__result {
			@apply w-full max-w-5xl mx-auto pt-0 sm:pt-5 grid grid-cols-1 gap-8;
		}
	}
}
