@tailwind components;
@tailwind utilities;

@layer components {
	.admin__dashboard {
		@apply p-4 sm:p-10;

		.main__content {
			@apply mt-10 p-3 bg-white border rounded-lg shadow-lg shadow-slate-400/30;
		}
	}
}
