@tailwind components;
@tailwind utilities;

@layer components {
	.createDashboard {
		@apply pt-10 pb-14 flex items-center justify-center bg-slate-100;

		.box {
			@apply w-full max-w-lg p-8 flex flex-col items-center mt-12 sm:mt-0;

			& > h1 {
				@apply mb-4 text-2xl font-bold;
			}

			.form__body {
				@apply w-full flex flex-col gap-4;

				.form__group {
					@apply w-full;

					h4 {
						@apply ml-4 mb-1 text-slate-500 tracking-wide;
					}

					.input__group {
						@apply h-12 flex items-center bg-white rounded-md shadow-sm border border-slate-200 focus-within:border-emerald-400 transition duration-200 ease-in-out;

						.icon {
							@apply ml-4 text-xl fill-slate-400;
						}

						input[type='text'] {
							@apply w-full px-4 py-2 border-none ring-0 bg-transparent placeholder:text-slate-400 tracking-wide;
						}
					}

					& > input[type='text'],
					& > input[type='number'] {
						@apply w-full h-12 px-4 rounded-md shadow-sm border placeholder:text-slate-400 border-slate-200 focus:ring-0 focus:border-emerald-400 transition duration-200 ease-in-out;
					}

					& > input[type='file'] {
						@apply w-full py-2 px-1;

						&::-webkit-file-upload-button {
							@apply mr-4 px-4 py-1 bg-white rounded-md outline-none border-none ring-1 ring-slate-400 text-slate-500;
						}
					}

					.appointmentType_label {
						@apply w-full h-12 px-4 flex items-center gap-2 bg-white rounded-md shadow-sm border border-slate-200 focus-within:border-emerald-400 transition duration-200 ease-in-out;

						input[type='radio'] {
							@apply w-4 h-4 rounded-full border text-emerald-400 border-slate-200 focus:ring-0 focus:border-emerald-400 transition duration-200 ease-in-out;
						}
					}

					.timeTable {
						@apply w-full p-5 flex flex-col gap-y-6 sm:gap-4 rounded-md shadow-sm border border-slate-200 bg-white transition duration-200 ease-in-out text-sm;

						.timeTable__day {
							.timeTable__day__time {
								@apply w-full flex items-center justify-between flex-col sm:flex-row;

								.block {
									@apply w-full flex items-center justify-between;

									p {
										@apply mr-2 py-3 w-12 text-center rounded-md text-sm bg-gray-200;
									}

									input[type='time'] {
										@apply h-11 w-full px-3 rounded-md shadow-sm border placeholder:text-slate-400 border-slate-200 focus:ring-0 focus:border-emerald-400 transition duration-200 ease-in-out;
									}

									.timeTable__day__checkbox {
										@apply ml-2 w-12 py-[0.65rem] flex items-center justify-center bg-emerald-500 rounded-md cursor-pointer shadow-lg shadow-emerald-500/30;

										.icon {
											@apply fill-white text-lg;
										}
									}
								}

								span {
									@apply inline-block px-2;
								}
							}
						}
					}

					.specialties__list {
						@apply mt-3 flex items-start gap-4 flex-wrap;

						.selected__specialties {
							@apply py-1 pl-2 pr-1 flex items-center bg-gray-200 rounded shadow;

							.icon {
								@apply ml-1 text-lg stroke-slate-700;
							}
						}
					}

					.session__duration {
						@apply w-full grid grid-cols-3 gap-4 text-sm;

						.session-duration__item {
							@apply py-2 px-2 sm:py-3 sm:px-4 flex items-center gap-2 bg-white rounded-md shadow-sm border border-slate-200 focus-within:border-emerald-400 transition duration-200 ease-in-out;

							input[type='radio'] {
								@apply w-4 h-4 rounded-full border text-emerald-400 border-slate-400 focus:ring-0 focus:border-emerald-400 transition duration-200 ease-in-out;
							}

							&.active {
								// @apply bg-emerald-400;

								// span {
								// 	@apply text-white;
								// }

								// input {
								// 	@apply border-white;
								// }

								@apply border-emerald-400;

								input[type='radio'] {
									@apply border-emerald-400;
								}

								span {
									@apply text-emerald-500 font-medium;
								}
							}
						}
					}
				}
			}

			.form__footer {
				@apply w-full flex items-center justify-center gap-4 mt-10;

				button {
					@apply w-full h-12 rounded-md text-white font-medium tracking-wide shadow-lg shadow-emerald-500/30 bg-emerald-500 hover:bg-[#22B573] focus:outline-none focus:ring-2 focus:ring-emerald-400 focus:ring-opacity-50 transition duration-200 ease-in-out;
				}
			}
		}
	}
}
