@tailwind components;
@tailwind utilities;

@layer components {
	.navbar__modal {
		@apply w-full min-h-screen max-h-full fixed top-0 left-0 z-50 py-16 sm:py-10 bg-white/40 flex justify-center overflow-y-scroll;
		backdrop-filter: blur(30px);

		.btn-close {
			@apply w-11 h-11 bg-gray-200 fixed top-3 right-4 rounded-full flex items-center justify-center cursor-pointer;

			.icon {
				@apply w-7 stroke-[#117446] h-7;
			}
		}

		.modal__container {
			@apply w-full max-w-5xl h-full mx-auto px-4 sm:px-0;

			.search__container {
				@apply sm:hidden w-full flex items-center mt-4 bg-white rounded-lg border border-emerald-700 pl-4 pr-3;

				input {
					@apply px-0 h-11 flex-grow bg-transparent text-[#117446] placeholder:text-[#117446] outline-none border-none ring-0 text-sm;
				}

				.icon {
					@apply stroke-[#117446] w-7 h-7;
				}
			}

			.for__users {
				@apply w-full flex flex-col gap-2 mt-5;

				a {
					@apply text-slate-500;
				}
			}

			.nav__one {
				@apply w-full flex flex-col mt-5;

				.header__bar {
					@apply w-full flex items-center justify-between;

					h1 {
						@apply text-[#15e584] text-2xl sm:text-3xl font-bold;
					}

					.icon {
						@apply w-7 h-7 sm:w-8 sm:h-8 bg-[#15e584] rounded-full p-1 stroke-white;
					}
				}

				.nav__links {
					@apply w-full mt-4 sm:mt-5 p-5 sm:p-6 flex items-center flex-wrap gap-4 border-dashed border-2 border-gray-400 rounded-xl;

					.nav__link {
						@apply py-1 pl-1 pr-2 sm:py-2 sm:pl-2 sm:pr-3 flex items-center gap-2 text-[#117446] rounded-2xl bg-white;
						box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

						img {
							@apply w-7 h-7 sm:w-9 sm:h-9;
						}

						span {
							@apply text-sm sm:text-base text-[#117446] font-bold;
						}
					}
				}
			}

			.nav__two {
				@apply flex mt-5 w-full flex-col;

				.header__bar {
					@apply w-full flex items-center justify-between;

					h1 {
						@apply text-[#15e584] text-2xl sm:text-3xl font-bold;
					}

					.icon {
						@apply w-7 h-7 sm:w-8 sm:h-8 bg-[#15e584] rounded-full p-1 stroke-white;
					}
				}

				.nav__links__container {
					@apply w-full border-dashed border-2 border-gray-400 rounded-xl mt-4 sm:mt-5 p-5;

					.title__bar {
						@apply w-full flex items-center justify-between;

						h1 {
							@apply text-[#117446] font-bold text-lg sm:text-xl;
						}

						.icon {
							@apply w-5 sm:w-6 h-5 sm:h-6 stroke-[#117446] rounded-full;
						}
					}

					.nav__links {
						@apply w-full flex items-center flex-wrap gap-4 mt-2 sm:mt-3;

						.nav__link {
							@apply border border-[#117446] text-[#117446] text-sm sm:text-base font-bold rounded-xl px-2 sm:px-3 py-1 sm:py-2 bg-white/10 flex items-center gap-2;
							backdrop-filter: blur(10px);
						}
					}

					&:last-child {
						@apply hidden sm:block;
					}
				}
			}
		}
	}
}
