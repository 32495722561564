@tailwind components;
@tailwind utilities;

@layer components {
    .doctor_card {
        @apply w-[900px] rounded-lg shadow-lg bg-white mx-auto m-32 p-10;
        h3 {
            @apply text-lg font-bold;
        }
        .doc_info1 {
            @apply grid grid-cols-2 gap-24;
            .info {
                @apply flex items-center gap-8;
                .specialty {
                    @apply bg-gray-400 text-white text-sm px-2 rounded-xl;
                }
            }
            img {
                @apply w-36 rounded;
            }
        }
        .doc_info2 {
            @apply grid grid-cols-3 gap-24 mt-10;
        }
        .doc_info3 {
            @apply grid grid-cols-3 gap-24 my-5;
        }
        .doc_info4 {
            @apply grid grid-cols-3 gap-24 my-5;
            .diseases_solve {
                .diseases {
                    @apply bg-gray-400 text-white text-sm px-2 rounded-xl;
                }
            }
        }
        .doc_card_btn {
            @apply bg-[#22B573] text-white w-44 text-center px-2 py-3 mt-5 rounded-xl;
        }
    }
}
