@tailwind components;
@tailwind utilities;

@layer components {
	.admin__login {
		@apply flex flex-col items-center justify-center h-screen;

		.box {
			@apply flex flex-col items-center justify-center w-96 p-8 bg-white rounded-lg shadow-xl;

			& > h1 {
				@apply text-2xl font-bold text-gray-800;
			}

			& > form {
				@apply flex flex-col items-center justify-center w-full mt-6;

				.form__group {
					@apply flex flex-col items-start justify-start w-full;

					& > label {
						@apply font-semibold tracking-wide text-gray-800;
					}

					& > input {
						@apply w-full mt-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500;
					}

					&:not(:last-child) {
						@apply mb-4;
					}
				}

				.error {
					@apply w-full text-center text-rose-500;
				}

				button {
					@apply w-full mt-4 p-2 bg-gray-800 text-white text-lg rounded-lg shadow-xl hover:bg-gray-700;
				}
			}
		}
	}
}
