@tailwind components;
@tailwind utilities;

@layer components {
	.prescription {
		@apply w-full min-h-screen bg-[#fbfbfb] flex justify-center p-5 sm:p-10;

		.box {
			@apply w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg p-5 sm:p-10;

			.sec__header {
				@apply pl-0 sm:pl-10 text-[#15e584] text-3xl font-bold mb-5;

				&:not(:first-child) {
					@apply mt-10;
				}
			}

			.sec__one {
				@apply p-4 sm:p-5 w-full flex items-start mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply w-32 h-32 flex-shrink-0 bg-slate-300 flex items-center justify-center;

					.cover {
						@apply w-full overflow-hidden rounded-lg;

						img {
							@apply w-full h-full object-cover;
						}
					}
				}

				.right {
					@apply w-full flex flex-col gap-2;

					h3 {
						@apply text-lg text-[#117446] font-medium;
					}

					.disease {
						@apply w-full flex items-center flex-wrap;

						.add {
							@apply w-full flex items-center gap-2;

							img {
								@apply w-5 h-5;
							}

							p {
								@apply text-[#117446] text-base;
							}

							.icon {
								@apply w-[0.9rem] h-[0.9rem] fill-[#117446] ml-1;
							}
						}
					}

					.info {
						@apply mt-1 w-full flex items-center gap-2 flex-wrap;

						.item {
							@apply flex items-center gap-2 p-1 border-[#15e584] border-2 rounded-xl;

							p {
								@apply text-[#117446] text-sm py-1 px-2 rounded-lg;

								background: linear-gradient(
									180deg,
									#14ffa1 0%,
									#15e584 100%
								);
							}

							span {
								@apply text-[#117446] text-base pr-2;
							}
						}

						.addItem {
							@apply flex items-center gap-2;

							.icon {
								@apply w-9 h-9 p-1 bg-[#15fb9c] stroke-[#117446] rounded-xl cursor-pointer;

								&:last-child {
									@apply p-1.5;
								}
							}
						}
					}
				}
			}

			.sec__empty {
				@apply p-4 sm:p-5 w-full flex justify-center mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				h1 {
					@apply text-[#117446] text-xl font-medium text-center py-5;
				}
			}

			.sec__two {
				@apply p-4 sm:p-5 w-full flex items-start mb-5 gap-4 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply flex-grow w-full grid grid-cols-1 sm:grid-cols-3 gap-4;

					.block {
						.block__content {
							@apply w-full flex flex-col items-center gap-2 p-2 border-[#15e584] border-2 rounded-xl;

							h3 {
								@apply w-full text-center text-[#117446] py-1 rounded-lg;

								background: linear-gradient(
									180deg,
									#14ffa1 0%,
									#15e584 100%
								);
							}

							textarea {
								@apply w-full h-24 resize-y p-1 border-none ring-0;
							}
						}
					}
				}

				.right {
					@apply flex items-center gap-2;

					.icon {
						@apply w-9 h-9 p-1 bg-[#15fb9c] stroke-[#117446] rounded-xl cursor-pointer;

						&:last-child {
							@apply p-1.5;
						}
					}
				}
			}

			.sec__test {
				@apply p-4 sm:p-5 w-full flex mb-5 border-2 border-dashed border-slate-400 rounded-3xl;

				.left {
					@apply w-full max-w-[13rem] pr-3 h-full max-h-[20rem] flex flex-col gap-2 overflow-y-auto mr-2;

					.category {
						@apply py-2 w-full bg-[#d9fbeb] text-[#2c9263] text-sm px-1 rounded-xl;

						&.active {
							background: linear-gradient(
								180deg,
								#14ffa1 0%,
								#15e584 100%
							);
						}
					}

					&::-webkit-scrollbar {
						width: 0.35rem;
					}

					&::-webkit-scrollbar-thumb {
						background: #93c6ae;
					}
				}

				.right {
					@apply flex-grow w-full grid grid-cols-1 sm:grid-cols-2 gap-4 pl-4 border-l-2 border-slate-300;

					.tests {
						@apply w-full h-full flex flex-col gap-2;

						.tests__container {
							@apply flex-grow w-full flex flex-col divide-y-2;

							label {
								@apply w-full flex items-center gap-2 py-2;

								input {
									@apply w-4 h-4 ring-[#15e584] text-[#15e584] rounded-full invisible;

									&:checked {
										@apply visible;
									}
								}

								span {
									@apply text-[#117446] text-sm;
								}
							}
						}

						.actions {
							@apply flex-shrink-0 w-full flex items-center justify-between gap-2;

							button {
								background: linear-gradient(
									180deg,
									#14ffa1 0%,
									#15e584 100%
								);

								@apply px-4 py-2 text-[#117446] rounded-lg;
							}
						}
					}

					.overview {
						@apply w-full flex flex-col;

						h1 {
							@apply flex-shrink-0 w-full flex items-center gap-2 py-2;

							span {
								@apply text-[#117446] text-xl font-bold;
							}
						}

						.folder__img {
							@apply flex-grow w-full h-full flex items-center justify-center;
						}
					}
				}
			}

			.ai__header {
				@apply pl-0 sm:pl-10 text-[#15e584] font-bold mb-5 inline-block text-center;

				img {
					@apply mx-auto;
				}

				p {
					@apply text-3xl mt-2 inline-block;
				}
			}

			.sec__three {
				@apply px-4 w-full grid grid-cols-1 sm:grid-cols-2 gap-5;

				.block {
					@apply w-full border-2 border-dashed border-slate-400 rounded-3xl p-8 flex flex-col gap-4;

					.genetic,
					.brandName {
						@apply w-full flex items-start gap-2;

						.left {
							@apply flex-grow flex flex-col;

							label {
								@apply text-[#15e584] text-base;
							}

							.input__field {
								@apply w-full flex items-center;

								.icon {
									@apply fill-[#117446] w-5 h-5;
								}

								input {
									@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1;
								}
							}
						}

						.right {
							@apply flex-shrink-0 flex flex-col gap-2;

							.icon {
								background: linear-gradient(
									180deg,
									#14ffa1 0%,
									#15e584 100%
								);

								@apply w-10 h-10 p-1.5 stroke-[#117446] rounded-xl cursor-pointer;
							}
						}
					}

					.brandName {
						@apply relative;

						.right {
							.btn-brand {
								.icon {
									@apply fill-[#15f090] p-1;
									background: transparent !important;
								}
							}

							.brand__dropdown {
								@apply absolute top-12 right-0 w-60 max-h-44 overflow-y-auto bg-slate-50 shadow-lg rounded-xl p-2 hidden flex-col gap-2;

								li {
									@apply w-full flex items-center gap-2 p-2 rounded-xl cursor-pointer border border-transparent hover:text-[#117446] hover:border-emerald-500 text-sm;
								}

								&.active {
									@apply flex;
								}
							}
						}
					}

					.timeTable {
						@apply w-full flex flex-col;

						& > p {
							@apply text-[#15e584] text-lg font-medium;
						}

						.times__container {
							@apply w-full flex items-start gap-2 mt-2;

							.times {
								@apply flex-grow w-full grid grid-cols-3 gap-2;

								.time {
									@apply w-full flex flex-col items-center justify-center p-1 border-[#15e584] border rounded-xl;

									label {
										background: linear-gradient(
											180deg,
											#14ffa1 0%,
											#15e584 100%
										);

										@apply w-full text-[#117446] text-center text-xs py-1 rounded-lg;

										input {
											@apply placeholder:text-[#117446] text-[#117446] bg-transparent text-xs p-0;
										}
									}

									input {
										@apply w-full border-none ring-0 text-[#117446] text-center text-sm pt-1 pb-0;

										&::-webkit-inner-spin-button,
										&::-webkit-outer-spin-button {
											-webkit-appearance: none;
											margin: 0;
										}
									}
								}
							}

							.addTime {
								@apply border border-[#15e584] h-[3.4rem] px-3 rounded-xl;

								.icon {
									@apply stroke-[#117446] text-4xl;
								}
							}
						}
					}

					.duration,
					.intake,
					.note {
						@apply w-full flex flex-col;

						label {
							@apply text-[#15e584] text-base;
						}

						.input__field {
							@apply w-full flex items-center;

							.icon {
								@apply fill-[#117446] w-5 h-5;
							}

							input {
								@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1;
							}
						}
					}
				}
			}

			.sec__three__sub {
				@apply w-full flex justify-end mt-4 mb-5;

				button {
					@apply px-4 py-2 flex items-center mr-5;

					span {
						@apply text-[#117446] text-lg pr-4;
					}

					.icon {
						@apply w-9 h-9 p-2 bg-[#15fb9c] fill-[#117446] rounded-xl cursor-pointer;
					}
				}
			}

			.sec__four {
				@apply w-full flex items-center px-8;

				.left {
					@apply flex-grow grid grid-cols-3 gap-4;

					.block {
						@apply flex flex-col gap-2;

						label {
							@apply flex items-center text-[#15e584] fill-[#15e584] gap-2;
						}

						.input__field {
							@apply w-full flex items-center;

							.icon {
								@apply fill-[#117446] stroke-[#117446] w-5 h-5 text-xl;
							}

							input {
								@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1;

								&::-webkit-file-upload-button {
									display: none;
								}
							}
						}

						&:last-child {
							.input__field {
								.icon {
									@apply mr-2;
								}
							}
						}
					}
				}

				.right {
					@apply sm:pl-20 flex-shrink-0 flex items-center gap-2;

					button {
						@apply px-8 py-2 bg-[#15fb9c] text-[#117446] text-lg font-semibold rounded-xl cursor-pointer;
					}
				}
			}
		}
	}
}
