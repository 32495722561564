@tailwind components;
@tailwind utilities;

@layer components {
	.medBox {
		@apply w-full border-2 border-dashed border-slate-400 rounded-xl p-4 sm:p-8 flex flex-col gap-4;

		.genetic,
		.brandName {
			@apply w-full flex items-start gap-2;

			.left {
				@apply relative flex-grow flex flex-col;

				label {
					@apply text-[#15e584] text-base;
				}

				.input__field {
					@apply w-full flex items-center;

					.icon {
						@apply fill-[#117446] w-5 h-5;
					}

					input {
						@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1;
					}
				}

				.geneticDropdown,
				.brandDropdown {
					@apply absolute top-full left-0 w-full max-h-[11rem] bg-white rounded-xl flex flex-col  z-[999] overflow-y-auto;

					box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

					.geneticItem,
					.brandItem {
						@apply px-2.5 py-2 text-sm font-medium text-[#117446] cursor-pointer hover:bg-emerald-100;
					}
				}
			}

			.right {
				@apply relative flex-shrink-0 flex flex-col gap-2;

				.icon {
					background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

					@apply w-10 h-10 p-1.5 stroke-[#117446] rounded-xl cursor-pointer;
				}
			}
		}

		.brandName {
			@apply relative;

			.right {
				.btn-brand {
					.icon {
						@apply fill-[#15f090] p-1;
						background: transparent !important;
					}
				}
			}
		}

		.timeTable {
			@apply w-full flex flex-col;

			& > p {
				@apply text-[#15e584] text-lg font-medium;
			}

			.injection__time__container {
				@apply flex items-center flex-col sm:flex-row gap-2 mt-2;

				select {
					@apply w-full sm:w-auto sm:flex-shrink-0 h-10 border border-[#15e584] ring-0 text-[#117446] text-center rounded-md;

					&::-webkit-inner-spin-button,
					&::-webkit-outer-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}
				}

				.injectionTime {
					@apply w-full sm:w-auto sm:flex-grow flex h-10 items-center gap-2 border border-[#15e584] rounded-md p-1;

					input {
						@apply w-10 h-8 border border-[#15e584] ring-0 text-[#117446] text-center rounded;
					}

					span {
						@apply text-[#117446] text-sm;
					}
				}
			}

			.saline__time__container {
				@apply p-1 flex items-center justify-center gap-2 mt-2 border border-[#15e584] rounded-md text-[#117446] text-sm;

				input {
					@apply w-10 h-8 border border-[#15e584] ring-0 text-[#117446] text-center rounded px-0;
				}
			}

			.times__container {
				@apply w-full flex flex-col gap-3 mt-2;

				.time__wrapper {
					@apply w-full flex items-start gap-2;

					.times {
						@apply flex-grow w-full grid grid-cols-2 sm:grid-cols-4 gap-2;

						.time {
							@apply w-full flex flex-col items-center justify-center p-1 border-[#15e584] border rounded-xl;

							label {
								background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

								@apply w-full text-[#117446] text-center text-xs py-1 rounded-lg px-2 sm:px-0;

								input {
									@apply placeholder:text-[#117446] text-[#117446] bg-transparent text-xs p-0;
								}
							}

							input {
								@apply w-full border-none ring-0 text-[#117446] text-center text-sm pt-1 pb-0;

								&::-webkit-inner-spin-button,
								&::-webkit-outer-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
							}
						}
					}

					.addTime {
						@apply border border-[#15e584] h-[3.4rem] px-3 rounded-xl;

						.icon {
							@apply stroke-[#117446] text-4xl;
						}
					}
				}
			}

			.new__times__container {
				@apply w-full mt-2;

				.new__times__wrapper {
					@apply w-full flex items-center pr-12;

					.new__timeItem {
						@apply w-full flex flex-col items-center justify-center;

						label {
							@apply text-[#117446] text-base font-semibold;
						}

						.blockItem {
							@apply relative w-full h-10 flex items-center justify-center bg-emerald-100 border border-white;

							input {
								@apply w-[inherit] text-center border-none outline-none ring-0 bg-transparent;

								&::-webkit-inner-spin-button,
								&::-webkit-outer-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
							}

							button {
								@apply absolute right-0 px-2 h-[39px] bg-emerald-100 hover:bg-emerald-200;
							}
						}

						.blockItem2 {
							@apply relative w-full h-10 flex items-center justify-center bg-emerald-100 border border-white divide-x-2 divide-white divide-solid;

							input {
								@apply flex-grow h-10 text-center border-none outline-none ring-0 bg-transparent;

								&::-webkit-inner-spin-button,
								&::-webkit-outer-spin-button {
									-webkit-appearance: none;
									margin: 0;
								}
							}

							select {
								@apply flex-shrink-0 h-10 border-none outline-none ring-0 bg-transparent text-sm text-slate-600;
							}

							.addTimeRow {
								@apply absolute -right-10 top-[2px] text-[#117446] w-8 h-8 flex items-center justify-center rounded hover:bg-emerald-100;

								border: 1px solid #15e584 !important;
							}
						}

						&.extra {
							@apply text-[#117446] w-52;
						}
					}
				}
			}
		}

		.duration,
		.note {
			@apply w-full flex flex-col;

			label {
				@apply text-[#15e584] text-base;
			}

			.input__field {
				@apply w-full flex items-center;

				.icon {
					@apply fill-[#117446] w-5 h-5;
				}

				input[type='text'] {
					@apply text-[#117446] placeholder:text-[#117446] border-none ring-0 w-full py-1;
				}

				.opt {
					@apply w-full flex items-center gap-2;

					input {
						@apply text-[#15e584] ring-[#15e584] w-4 h-4;
					}

					& > label {
						@apply text-[#117446] text-sm;
					}
				}

				&:has(.opt) {
					@apply flex-col sm:flex-row sm:items-center;
				}
			}
		}

		.intake {
			@apply w-full flex flex-col;

			label {
				@apply text-[#15e584] text-base;
			}

			.input__field {
				@apply w-full flex flex-col sm:flex-row sm:items-center gap-2 mt-2;

				.opt {
					@apply w-full flex items-center gap-2;

					input {
						@apply text-[#15e584] ring-[#15e584] w-4 h-4;
					}

					& > label {
						@apply text-[#117446] text-sm;
					}
				}
			}
		}
	}
}
