@tailwind components;
@tailwind utilities;

@layer components {
	.printPres {
		@apply w-full flex justify-center bg-emerald-50 py-10;

		.box {
			@apply relative w-full max-w-6xl mx-auto flex flex-col bg-white rounded-2xl shadow-lg overflow-hidden pb-20;

			.btn-print {
				@apply absolute bottom-8 right-10 flex items-center gap-2 px-4 py-2 rounded-2xl;

				background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

				.icon {
					@apply fill-[#117446] w-8 h-8;
				}

				span {
					@apply text-[#117446] text-xl uppercase font-bold;
				}
			}
		}
	}

	#innerBox {
		@apply bg-white p-16 flex-grow;

		.info {
			@apply w-full grid grid-cols-2 gap-4;

			& > .left {
				@apply text-[#117446] flex gap-[0.2rem] flex-col;

				.logo {
					@apply w-32;

					img {
						@apply w-full;
					}
				}

				.creationDate {
					@apply text-sm ml-4 mt-1;
				}

				.issuedTo {
					@apply text-sm;
				}

				.ePres {
					@apply text-4xl mt-4 mb-3;
				}

				.warn {
					@apply flex items-center gap-2 my-0;

					.icon {
						@apply fill-[#117446] w-5 h-5;
					}

					span {
						@apply text-[#117446] text-sm;
					}
				}

				.expireDate {
					@apply flex flex-col items-start gap-1;

					span {
						@apply border-2 border-dashed text-xs border-rose-400 rounded-md px-2 py-1;
					}
				}
			}

			.right {
				@apply text-[#117446] text-xs text-right flex flex-col items-end;

				.signature {
					@apply w-28;

					img {
						@apply w-full;
					}
				}
			}
		}

		.bottom_sec {
			@apply flex;

			& > .left {
				@apply mt-5 w-full max-w-[12rem] flex flex-col gap-2;

				& > h4 {
					@apply text-[#117446] text-sm font-semibold;
				}

				.medicalRecord p {
					@apply text-[#117446] text-xs;

					span:first-child {
						@apply font-semibold block;
					}

					span:last-child {
						@apply block;
					}
				}
			}

			.med__sec {
				@apply w-full mt-5;

				.givenMedicines {
					@apply w-full grid grid-cols-2 gap-2;

					.medicineItem {
						@apply w-full border border-dashed border-emerald-400 rounded-xl p-3 flex flex-col gap-1;

						p {
							@apply text-[#117446] text-xs;

							span:first-child {
								@apply text-slate-500 font-semibold;
							}
						}

						.medTimeTable {
							@apply w-full flex flex-wrap gap-1;

							span {
								@apply text-[#117446] text-xs px-1 py-0.5 rounded-md border border-emerald-300;
							}
						}

						.intakeDuration {
							@apply w-full flex items-center justify-between gap-1;
						}
					}
				}
			}

			&:has(> .left) .med__sec {
				@apply flex-grow border-l border-emerald-400 pl-5;
			}
		}
	}
}
