@tailwind components;
@tailwind utilities;

@layer components {
	.editProfile {
		@apply w-full max-w-5xl mx-auto px-5 sm:px-0 flex flex-col items-center mt-4 sm:mt-0;

		& > h1 {
			@apply mt-4 text-2xl font-semibold text-slate-500;
		}

		.box {
			@apply w-full py-5 flex flex-col gap-x-7 items-center sm:items-start;

			.group__one {
				@apply w-full flex flex-col sm:flex-row items-center sm:items-start;

				.left {
					@apply p-5 max-w-[16rem] w-full;

					.avatar {
						@apply w-44 h-44 sm:w-32 sm:h-32 mx-auto mb-4 rounded-full border-[4px] border-emerald-400 overflow-hidden flex items-center justify-center;

						img {
							@apply w-full h-full object-cover;
						}

						svg {
							@apply w-12 h-12 text-emerald-400;
						}
					}

					& > input[type='file'] {
						@apply w-full py-2 px-1 outline-none border-none text-sm;

						&::-webkit-file-upload-button {
							@apply mr-3 px-2 py-1 bg-white rounded-md outline-none border-none ring-1 ring-slate-400 text-sm text-slate-500;
						}
					}
				}

				.right {
					@apply w-full p-5 pt-5 sm:pt-7 flex-grow flex flex-col gap-y-5;

					.form__group {
						@apply w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 gap-y-4 sm:gap-y-0;

						&.no-specialty {
							@apply sm:grid-cols-1;
						}

						.input__group {
							@apply w-full flex flex-col gap-y-1;

							& > label {
								@apply text-slate-600 pb-1;
							}

							& > input[type='text'],
							& > input[type='tel'] {
								@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
							}
						}
					}
				}
			}

			.group__two {
				@apply mt-2 w-full flex flex-col gap-y-8 px-5;

				.form__group {
					@apply w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 gap-y-4 sm:gap-y-0;

					&.no-specialty {
						@apply sm:grid-cols-1;
					}
				}

				.input__group {
					@apply w-full flex flex-col gap-y-1;

					& > label,
					& > h4 {
						@apply text-slate-600 pb-1;
					}

					& > input:not([type='file']),
					& > input:not([type='radio']) {
						@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80;
					}

					.radio__group {
						@apply w-full py-2 px-3 rounded-md border border-slate-300 flex items-center justify-between;

						.radio__item {
							@apply sm:w-1/2 flex items-center gap-x-2 sm:gap-x-3;

							& > label {
								@apply text-slate-600;
							}

							& > input[type='radio'] {
								@apply w-4 h-4 text-emerald-400 focus:ring-1 focus:ring-emerald-300 focus:outline-none;
							}
						}
					}

					.radio__group2 {
						@apply w-full py-2 px-3 rounded-md border border-slate-300 grid grid-cols-4 sm:flex sm:items-center sm:justify-between;

						.radio__item {
							@apply flex items-center gap-x-2 sm:gap-x-3;

							& > label {
								@apply text-slate-600;
							}

							& > input[type='radio'] {
								@apply w-4 h-4 text-emerald-400 focus:ring-1 focus:ring-emerald-300 focus:outline-none;
							}
						}
					}

					& > input[type='file'] {
						@apply w-full py-2 px-1 outline-none border-none;

						&::-webkit-file-upload-button {
							@apply mr-3 px-4 py-2 bg-white rounded-md outline-none border-none ring-1 ring-slate-400 text-sm text-slate-500;
						}
					}

					& > textarea {
						@apply w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none placeholder:text-slate-500/80 resize-none;
					}

					.specialties {
						@apply relative w-full py-2 px-3 rounded-md border border-slate-300 focus:ring-1 focus:ring-emerald-300 focus:outline-none cursor-pointer;

						* {
							@apply cursor-pointer;
						}

						.selected {
							@apply text-black whitespace-nowrap overflow-hidden overflow-ellipsis;

							&.empty {
								@apply text-slate-500/80;
							}
						}

						.dropdown {
							@apply absolute hidden top-10 left-0 w-full max-h-52 flex-col p-2 bg-white rounded-b-md border border-slate-300 shadow-lg z-10 overflow-y-auto;

							.item {
								@apply w-full pl-2 hover:bg-slate-100 flex items-center gap-x-2;

								input {
									@apply w-3 h-3;
								}

								label {
									@apply flex-grow p-2;
								}
							}

							&.open {
								@apply flex;
							}
						}
					}
				}

				.btn-addInput {
					@apply flex items-center text-sm px-1.5 py-1 border text-slate-600 border-emerald-400 rounded-md shadow-md transition-all duration-300;

					.icon {
						@apply text-lg;
					}
				}
			}

			.group__three {
				@apply w-full flex items-center justify-center p-10;

				.btn-save {
					@apply w-full max-w-[16rem] py-2 px-4 bg-emerald-400 text-white rounded-md shadow-md hover:bg-emerald-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300;
				}
			}
		}
	}
}
