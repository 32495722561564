@tailwind components;
@tailwind utilities;

@layer components {
	.printPres {
		@apply w-full flex justify-center bg-emerald-50 py-10;

		.box {
			@apply relative w-full max-w-6xl mx-auto flex flex-col bg-white rounded-2xl shadow-lg overflow-hidden pb-20;

			.btn-print {
				@apply absolute bottom-8 right-10 flex items-center gap-2 px-4 py-2 rounded-2xl;

				background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

				.icon {
					@apply fill-[#117446] w-8 h-8;
				}

				span {
					@apply text-[#117446] text-xl uppercase font-bold;
				}
			}
		}
	}

	#innerBox {
		@apply bg-white p-16 flex-grow;

		.info {
			@apply w-full grid grid-cols-2 gap-4;

			.left {
				@apply text-[#117446] flex gap-[0.2rem] flex-col;

				.logo {
					@apply w-32;

					img {
						@apply w-full;
					}
				}

				.creationDate {
					@apply text-sm ml-4 mt-1;
				}

				.issuedTo {
					@apply text-sm;
				}

				.ePres {
					@apply text-xl my-2;
				}

				.warn {
					@apply flex items-center gap-2 my-0;

					.icon {
						@apply fill-[#117446] w-5 h-5;
					}

					span {
						@apply text-[#117446] text-sm;
					}
				}

				.expireDate {
					@apply flex flex-col items-start gap-1;

					span {
						@apply border-2 border-dashed text-xs border-rose-400 rounded-md px-2 py-1;
					}
				}
			}

			.right {
				@apply text-[#117446] text-xs text-right flex flex-col items-end;

				.signature {
					@apply w-28;

					img {
						@apply w-full;
					}
				}
			}
		}

		.testsIssueSec {
			@apply w-full mt-5 grid grid-cols-2 gap-4;

			.left {
				@apply flex flex-col gap-2;

				& > h2 {
					@apply text-[#117446] font-medium text-base mb-1;
				}

				p {
					@apply text-[#117446] text-xs;

					span {
						@apply font-semibold;
					}
				}
			}

			.right {
				@apply flex flex-col gap-2;

				& > h2 {
					@apply text-[#117446] font-medium text-base mb-1;
				}

				p {
					@apply text-[#117446] text-xs;

					span {
						@apply font-semibold;
					}
				}
			}
		}
	}
}
