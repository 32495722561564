@tailwind components;
@tailwind utilities;

@layer components {
	.feed {
		@apply w-full bg-emerald-50 pt-4 pb-8;

		.feed__container {
			@apply w-full max-w-5xl grid grid-cols-12 gap-4 mx-auto;

			.my__profile {
				@apply relative w-full flex flex-col gap-4 bg-white shadow-md rounded-xl overflow-hidden;

				.top {
					@apply w-full pb-3 pt-6 px-3 flex flex-col items-center justify-center border-b border-slate-300;

					.avatar {
						@apply relative w-20 h-20 rounded-full overflow-hidden z-[2] border-2 border-white;

						img {
							@apply w-full h-full object-cover;
						}
					}

					.info {
						@apply w-full flex flex-col items-center gap-1 mt-2;

						h1 {
							@apply text-lg font-semibold text-slate-600;
						}
					}
				}

				.connections {
					@apply w-full flex flex-col gap-2 px-3 pb-3;

					p {
						@apply w-full flex items-center justify-between font-medium;

						span {
							@apply text-sm;

							&:first-of-type {
								@apply text-slate-500;
							}

							&:last-of-type {
								@apply text-emerald-600;
							}
						}
					}
				}

				&::before {
					@apply content-[''] absolute top-0 left-0 w-full h-16 z-[1] bg-black;
				}
			}

			.create__post {
				@apply w-full px-3 pt-3 pb-1 flex flex-col bg-white rounded-md shadow-md overflow-hidden;

				.top {
					@apply w-full flex items-center gap-4;

					.left {
						@apply flex-shrink-0 w-10 sm:w-16 h-10 sm:h-16 rounded-full overflow-hidden;

						img {
							@apply w-full h-full object-cover;
						}
					}

					.right {
						@apply flex-grow w-full h-10 sm:h-[3.8rem] flex items-center justify-center;

						// textarea {
						// 	@apply w-full h-full resize-none bg-transparent ring-0 border-2 border-slate-300 focus:border-slate-400 rounded-lg;
						// }

						button {
							@apply w-full px-4 h-full resize-none bg-transparent ring-0 border-2 border-slate-300 rounded-md text-left;
						}
					}
				}

				.bottom {
					@apply w-full flex items-center justify-between mt-1;

					.block {
						@apply flex items-center gap-2 px-4 py-2 hover:bg-gray-300 rounded-md;

						.icon {
							@apply text-xl sm:text-lg;
						}

						span {
							@apply text-sm hidden sm:inline-block;
						}

						&:last-of-type {
							@apply px-3;
						}

						&:nth-child(3),
						&:nth-child(4) {
							.icon {
								@apply text-xl;
							}
						}

						.photo {
							@apply fill-emerald-600;
						}

						.video {
							@apply fill-green-600;
						}

						.article {
							@apply fill-amber-600;
						}

						.problems {
							@apply stroke-rose-600;
						}
					}
				}
			}

			.posts {
				@apply mt-4 w-full flex flex-col gap-4;

				.post {
					@apply w-full flex flex-col bg-white rounded-md shadow-md overflow-hidden;

					.top {
						@apply w-full flex items-center gap-2 p-3;

						.avatar {
							@apply flex-shrink-0 w-14 h-14 rounded-full overflow-hidden;

							img {
								@apply w-full h-full object-cover;
							}
						}

						.info {
							@apply flex-grow w-full flex flex-col gap-1 truncate;

							h1 {
								@apply text-sm font-medium text-slate-600;
							}

							p {
								@apply text-xs text-slate-500;
							}
						}

						button {
							@apply flex-shrink-0 flex items-center gap-1 p-1.5 rounded-md hover:bg-emerald-100;

							.icon {
								@apply w-4 h-4 fill-emerald-600;
							}

							span {
								@apply text-sm font-medium text-emerald-600;
							}
						}
					}
					.content {
						@apply w-full flex flex-col gap-2;

						p {
							@apply w-full px-4 pt-1 text-sm text-slate-700;
						}

						img {
							@apply w-full;
						}

						video {
							@apply w-full;
						}
					}

					.bottom {
						@apply w-full flex flex-col gap-2 px-3;

						.stats {
							@apply w-full flex items-center justify-between py-1.5;

							.left {
								@apply relative flex items-center gap-2;

								img {
									@apply w-4 h-4;

									&:nth-child(1) {
										@apply absolute top-0 left-0;
									}

									&:nth-child(2) {
										@apply absolute top-0 left-3;
									}

									&:nth-child(3) {
										@apply absolute top-0 left-6;
									}
								}

								p {
									@apply ml-11 text-xs text-slate-500;
								}

								&:not(:has(> img)) {
									p {
										@apply ml-0;
									}
								}

								&:has(> img:nth-child(1)) {
									p {
										@apply ml-5;
									}
								}

								&:has(> img:nth-child(2)) {
									p {
										@apply ml-8;
									}
								}

								&:has(> img:nth-child(3)) {
									p {
										@apply ml-11;
									}
								}
							}

							.right {
								@apply flex items-center gap-2 text-slate-500;

								p {
									@apply text-xs text-slate-500;
								}
							}
						}

						.actions {
							@apply w-full grid grid-cols-4 gap-2 p-1.5;

							.btn-action {
								@apply relative w-full flex items-center justify-center gap-2 py-2 hover:bg-slate-200 rounded-md;

								.all-reacts {
									@apply absolute bottom-[calc(100%+0.5rem)] left-0 flex items-center gap-1 p-1 bg-white rounded-md opacity-0 transition-opacity duration-200 delay-500;

									box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);

									.btn-reaction {
										@apply w-10 h-10 overflow-hidden p-1.5 hover:bg-gray-200 rounded-md;

										img {
											@apply w-full h-full object-cover;
										}

										&.active {
											@apply bg-emerald-100 rounded-md;
										}
									}
								}

								&:hover .all-reacts {
									@apply opacity-100;
								}

								& > img {
									@apply w-5;
								}

								.icon {
									@apply w-5 h-5 fill-emerald-600;
								}

								& > span {
									@apply hidden sm:inline-block mt-1 text-sm text-slate-600;
								}

								&.active {
									@apply bg-emerald-200 rounded-md;

									& > span {
										@apply text-emerald-600;
									}
								}
							}
						}
					}

					.comment__part {
						@apply w-full flex flex-col gap-2 py-2 mt-1 border-t border-slate-300;

						.comment__list {
							@apply w-full flex flex-col gap-2;

							.comment__item {
								@apply flex items-start gap-2 px-2 py-1;

								.comment__avatar {
									@apply flex-shrink-0 w-10 h-10 rounded overflow-hidden;

									img {
										@apply w-full h-full object-cover;
									}
								}

								.comment__info {
									@apply flex-grow w-full flex flex-col gap-1;

									.author-info {
										@apply flex items-center gap-1;

										h1 {
											@apply text-sm font-medium text-slate-600;
										}

										p {
											@apply text-xs text-slate-500;
										}
									}

									p {
										@apply text-sm text-slate-700;
									}
								}
							}
						}

						.comment__bottom {
							@apply w-full flex items-center gap-1 px-2;

							.comment__avatar {
								@apply flex-shrink-0 w-10 h-10 rounded overflow-hidden;

								img {
									@apply w-full h-full object-cover;
								}
							}

							.comment__input {
								@apply flex-grow w-full flex items-center gap-2 rounded bg-slate-100 focus-within:bg-slate-200;

								input {
									@apply w-full h-10 bg-transparent border-none ring-0 outline-none;
								}
							}

							.comment__actions {
								@apply flex-shrink-0 flex items-center gap-2;

								button {
									@apply flex items-center justify-center w-10 h-10 rounded bg-slate-200 hover:bg-slate-300;

									.icon {
										@apply w-4 fill-emerald-600;
									}
								}
							}
						}
					}
				}
			}

			.suggest {
				@apply flex w-full flex-col bg-white rounded-xl shadow-md overflow-hidden;

				h1 {
					@apply w-full p-3 font-medium text-slate-600 flex items-center justify-between;

					span {
						@apply text-sm;
					}

					.icon {
						@apply text-lg border-2 border-slate-600 rounded bg-slate-600 fill-white;
					}
				}

				.suggest__list {
					@apply w-full flex flex-col gap-2 px-3 pb-3;

					.suggest__item {
						@apply w-full flex flex-col gap-2;

						.top {
							@apply w-full flex items-center gap-1;

							.avatar {
								@apply w-12 h-10 rounded-full overflow-hidden;

								img {
									@apply w-full h-full object-cover;
								}
							}

							h1 {
								@apply text-sm font-medium text-slate-600;
							}
						}

						.info {
							@apply flex-grow w-full flex flex-col;

							p {
								@apply text-xs text-slate-500;
							}

							button {
								@apply mt-1 w-fit flex items-center gap-1 px-4 py-2 border border-slate-400 text-sm rounded-xl bg-white hover:bg-slate-200;

								.icon {
									@apply text-lg;
								}

								span {
									@apply font-medium text-slate-600;
								}
							}
						}
					}
				}
			}
		}

		.createPostPopup {
			@apply fixed top-0 left-0 z-50 w-screen h-screen flex justify-center bg-black/50 pt-20 overflow-y-auto;

			.createPostPopup__container {
				@apply w-full h-fit max-w-xl rounded-xl bg-white;

				.createPost__header {
					@apply w-full flex items-center justify-between px-6 pt-6 pb-4 border-b border-slate-200;

					h1 {
						@apply text-lg text-slate-600;
					}

					button {
						@apply flex items-center gap-1 px-2 py-1 rounded-md hover:bg-slate-200;

						.icon {
							@apply w-5 h-5 fill-slate-600;
						}

						span {
							@apply text-sm text-slate-600;
						}
					}
				}

				.createPost__body {
					@apply w-full flex flex-col gap-4 px-6 pt-5;

					.body__top {
						@apply w-full flex items-center gap-2;

						.body_avatar {
							@apply w-12 h-12 flex items-center;

							img {
								@apply object-cover rounded-full;
							}
						}

						.info {
							@apply flex-grow w-full flex flex-col truncate;

							h1 {
								@apply text-sm font-medium text-slate-600;
							}

							p {
								@apply text-xs text-slate-500;
							}
						}
					}

					.body__middle {
						@apply w-full;

						textarea {
							@apply w-full min-h-[6rem] p-0 text-slate-600 resize-none border-none ring-0 outline-none;
						}
					}

					.postImages {
						@apply w-full grid grid-cols-6 gap-2;

						.postImages__img {
							@apply w-full h-20 rounded-md overflow-hidden relative;

							img {
								@apply w-full h-full object-cover;
							}

							.postImages__img__overlay {
								@apply absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black/50;

								.icon {
									@apply w-8 h-8 fill-white;
								}
							}
						}
					}

					.vidPreview {
						@apply w-full rounded-md overflow-hidden relative;

						video {
							@apply w-full h-full object-cover;
						}

						.vidPreview__overlay {
							@apply absolute top-0 right-0 w-20 h-20 flex items-center rounded-bl-md justify-center bg-black/50;

							.icon {
								@apply w-8 h-8 fill-white;
							}
						}
					}
				}

				.createPost__footer {
					@apply w-full flex items-end justify-between gap-2 px-6 pb-4 pt-3;

					.footer__left {
						@apply flex items-center gap-2;

						label {
							@apply flex w-9 h-9 items-center justify-center p-1.5  rounded-full hover:bg-slate-200 cursor-pointer;

							.icon {
								@apply w-6 h-6 fill-slate-600;
							}
						}
					}

					.btn-post {
						@apply px-4 py-1 rounded-full bg-emerald-700/90 text-white font-medium disabled:text-slate-600 disabled:bg-slate-300 disabled:cursor-not-allowed;
					}
				}
			}
		}
	}
}
