@tailwind components;
@tailwind utilities;

@layer components {
	.navbar {
		@apply w-full border-b-2;
		border-bottom-color: #d6eae0;
		background: linear-gradient(180deg, #fdfdfd 0%, #f2fbf7 100%);

		.nav__container {
			@apply hidden sm:flex w-full max-w-5xl mx-auto px-4 items-center justify-between;
			height: 4.4rem;

			.left__side {
				@apply flex items-center gap-16;

				.brand {
					@apply flex items-center;

					img {
						@apply w-28 sm:w-32;
					}

					&.social_page {
						@apply hidden sm:flex;
					}
				}

				.for__users_nav {
					@apply hidden items-center gap-6;

					a {
						@apply text-slate-500;
					}

					&.auth {
						@apply gap-12 sm:flex;
					}
				}
			}

			.btn-signIn {
				@apply block sm:hidden text-sm font-medium border-2 px-4 py-1 rounded-md;
				color: #19a262;
				border-color: #19b96e;
			}

			.actions {
				@apply flex items-center gap-5;

				.social__nav {
					@apply fixed z-50 sm:relative bottom-0 sm:bottom-auto left-0 sm:left-auto w-full sm:w-auto bg-white sm:bg-transparent flex items-center justify-between sm:justify-start py-2 sm:py-0 px-6 sm:px-0 sm:gap-8 sm:mr-10;

					a {
						@apply relative flex flex-col items-center gap-0.5 rounded-md shadow-lg p-2 overflow-hidden;

						.notification__count {
							@apply absolute top-0 right-0 p-1 min-w-[1rem] bg-rose-500 text-white text-[10px] align-middle flex items-center justify-center rounded-full;
							font-weight: 500;
							line-height: 1;
						}

						img {
							@apply w-7;
						}
					}
				}

				.search__container {
					@apply hidden sm:block relative w-11 h-10 bg-white rounded transition-all duration-500 ease-in-out overflow-hidden border-2 border-[#1b935b] sm:border-transparent;

					.search__input {
						@apply relative w-72 h-10 bg-transparent flex items-center justify-center;
						top: -1px;

						input {
							@apply w-full h-full pl-4 bg-transparent text-sm font-medium outline-none border-none ring-0;
							color: #1b935b;

							&::placeholder {
								@apply text-sm;
								color: #1b935b;
							}
						}
					}

					.search__icon {
						@apply absolute right-0 w-10 h-10 rounded-xl bg-white flex items-center justify-center cursor-pointer;
						top: -1px;
						z-index: 2;

						.icon {
							@apply w-6 h-6;
							stroke: #1b935b;
						}
					}

					&.open {
						@apply border-[#1b935b] sm:w-[16rem] overflow-hidden;
					}

					&.social_page {
						@apply block sm:hidden;

						width: 12rem;
					}
				}

				.profile__action {
					@apply relative flex items-center justify-center;

					.btn-profile {
						.icon {
							@apply fill-[#1b935b] w-9 h-9;
						}

						img {
							@apply w-9 h-9 rounded-full object-cover;
						}
					}

					.dropdown__menu {
						@apply bg-white w-[300px] p-3 rounded-lg absolute top-14 -right-1 sm:-right-[1.6rem] z-[10000000] hidden;
						box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

						&::before {
							@apply absolute content-[''] -top-[10px] right-2 sm:right-8 w-5 h-5 bg-white transform rotate-45;
						}

						.top {
							@apply flex items-center gap-4 p-2 pb-4;

							.icon {
								@apply w-8 h-8 fill-emerald-500 inline-flex;
							}

							h3 {
								@apply text-slate-500 font-bold;
							}

							p {
								@apply text-sm text-emerald-600 font-medium;
							}
						}

						.item {
							@apply list-none hover:bg-slate-200 p-2 border-b-2;

							a {
								@apply flex items-center gap-2;

								img {
									@apply w-7 inline-flex;
								}

								.icon {
									@apply ml-1 w-5 h-5 fill-emerald-500 stroke-emerald-500;
								}
							}

							&:last-of-type {
								@apply border-b-0;
							}
						}

						.profile__settings {
							@apply my-[10px] py-2 border-y-[3px] border-slate-300;
						}

						hr {
							&:nth-of-type(2),
							&:nth-of-type(3) {
								@apply border-t-[3px] border-slate-300;
							}
						}

						.bottom {
							@apply flex flex-col items-start;

							button {
								@apply px-2 py-[0.3rem] hover:text-emerald-500;
							}
						}

						&.show {
							@apply block;
						}
					}
				}

				.auth__container {
					@apply hidden sm:flex items-center gap-5;

					.sign-in {
						@apply text-emerald-700 font-medium;
					}

					.sign-up {
						@apply px-4 py-2 text-sm font-medium rounded-lg;
						color: #117446;

						background: linear-gradient(180deg, #14fea0 0%, #15e584 100%);
					}
				}

				.menu__icon {
					@apply w-8 h-8 border-2 flex sm:hidden items-center justify-center rounded-md cursor-pointer;
					border-color: #1b935b;

					.icon {
						@apply w-6 h-6;
						stroke: #1b935b;
					}

					&.chat {
						border-color: transparent;
					}
				}
			}
		}

		.nav__container2 {
			@apply grid grid-cols-12 sm:hidden w-full h-[4.4rem] mx-auto px-4;

			.social__nav {
				@apply fixed bottom-0 left-0 w-full h-14 bg-white flex items-center justify-evenly z-[10000000] gap-4;

				a {
					@apply relative flex flex-col items-center gap-0.5 rounded-md shadow-lg p-2 overflow-hidden;

					.notification__count {
						@apply absolute top-0 right-0 p-1 min-w-[1rem] bg-rose-500 text-white text-[10px] align-middle flex items-center justify-center rounded-full;
						font-weight: 500;
						line-height: 1;
					}

					img {
						@apply w-7;
					}
				}
			}

			&.no-auth {
				.brand {
					@apply col-span-4 flex items-center;

					img {
						@apply w-28;
					}
				}

				.right__side {
					@apply col-span-8 flex items-center justify-end gap-5;

					.btn-signIn {
						@apply text-sm font-medium border-2 px-4 py-1 rounded-md;
						color: #19a262;
						border-color: #19b96e;
					}

					.menu__icon {
						@apply w-7 h-7 border-2 flex items-center justify-center rounded-md cursor-pointer;
						border-color: #1b935b;

						.icon {
							@apply w-6 h-6;
							stroke: #1b935b;
						}
					}
				}
			}

			&.auth {
				.brand {
					@apply col-span-4 flex items-center;

					img {
						@apply w-28;
					}
				}

				.right__side {
					@apply col-span-8 flex items-center justify-end gap-5;

					.menu__icon {
						@apply w-8 h-8 border-2 flex items-center justify-center rounded cursor-pointer;
						border-color: #1b935b;

						.icon {
							@apply w-6 h-6;
							stroke: #1b935b;
						}
					}

					.profile__action {
						@apply relative flex items-center justify-center;

						.btn-profile {
							.icon {
								@apply fill-[#1b935b] w-9 h-9;
							}

							img {
								@apply w-9 h-9 rounded-full object-cover;
							}
						}

						.dropdown__menu {
							@apply bg-white w-[300px] p-3 rounded-lg absolute top-14 -right-1 sm:-right-[1.6rem] z-[10000000] hidden;
							box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

							&::before {
								@apply absolute content-[''] -top-[10px] right-2 sm:right-8 w-5 h-5 bg-white transform rotate-45;
							}

							.top {
								@apply flex items-center gap-4 p-2 pb-4;

								.icon {
									@apply w-8 h-8 fill-emerald-500 inline-flex;
								}

								h3 {
									@apply text-slate-500 font-bold;
								}

								p {
									@apply text-sm text-emerald-600 font-medium;
								}
							}

							.item {
								@apply list-none hover:bg-slate-200 p-2 border-b-2;

								a {
									@apply flex items-center gap-2;

									img {
										@apply w-7 inline-flex;
									}

									.icon {
										@apply ml-1 w-5 h-5 fill-emerald-500 stroke-emerald-500;
									}
								}

								&:last-of-type {
									@apply border-b-0;
								}
							}

							.profile__settings {
								@apply my-[10px] py-2 border-y-[3px] border-slate-300;
							}

							hr {
								&:nth-of-type(2),
								&:nth-of-type(3) {
									@apply border-t-[3px] border-slate-300;
								}
							}

							.bottom {
								@apply flex flex-col items-start;

								button {
									@apply px-2 py-[0.3rem] hover:text-emerald-500;
								}
							}

							&.show {
								@apply block;
							}
						}
					}
				}
			}

			&.auth-social {
				@apply flex sm:hidden items-center gap-2 px-2;

				.profile__action {
					@apply flex-shrink min-w-[3rem] relative flex items-center justify-center;

					.btn-profile {
						.icon {
							@apply fill-[#1b935b] w-9 h-9;
						}

						img {
							@apply w-9 h-9 rounded-full object-cover;
						}
					}

					.dropdown__menu {
						@apply bg-white w-[300px] p-3 rounded-lg absolute top-14 -left-1 sm:-left-[1.6rem] z-[10000000] hidden;
						box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);

						&::before {
							@apply absolute content-[''] -top-[10px] left-5 sm:left-8 w-5 h-5 bg-white transform rotate-45;
						}

						.top {
							@apply flex items-center gap-4 p-2 pb-4;

							.icon {
								@apply w-8 h-8 fill-emerald-500 inline-flex;
							}

							h3 {
								@apply text-slate-500 font-bold;
							}

							p {
								@apply text-sm text-emerald-600 font-medium;
							}
						}

						.item {
							@apply list-none hover:bg-slate-200 p-2 border-b-2;

							a {
								@apply flex items-center gap-2;

								img {
									@apply w-7 inline-flex;
								}

								.icon {
									@apply ml-1 w-5 h-5 fill-emerald-500 stroke-emerald-500;
								}
							}

							&:last-of-type {
								@apply border-b-0;
							}
						}

						.profile__settings {
							@apply my-[10px] py-2 border-y-[3px] border-slate-300;
						}

						hr {
							&:nth-of-type(2),
							&:nth-of-type(3) {
								@apply border-t-[3px] border-slate-300;
							}
						}

						.bottom {
							@apply flex flex-col items-start;

							button {
								@apply px-2 py-[0.3rem] hover:text-emerald-500;
							}
						}

						&.show {
							@apply block;
						}
					}
				}

				.search__container {
					@apply flex-grow relative w-full h-9 bg-white rounded overflow-hidden border-[#1b935b] border-2;

					.search__input {
						@apply relative w-72 h-10 bg-transparent flex items-center justify-center;
						top: -1px;

						input {
							@apply w-full h-full pl-4 bg-transparent text-sm font-medium outline-none border-none ring-0;
							color: #1b935b;

							&::placeholder {
								@apply text-sm;
								color: #1b935b;
							}
						}
					}

					.search__icon {
						@apply absolute right-0 w-10 h-10 rounded-xl bg-white flex items-center justify-center cursor-pointer;
						top: -1px;
						z-index: 2;

						.icon {
							@apply w-6 h-6;
							stroke: #1b935b;
						}
					}
				}

				.chat__container {
					@apply flex-shrink min-w-[3rem] flex items-center justify-center;

					button {
						@apply w-8 h-8 flex items-center justify-center rounded-md cursor-pointer;

						.icon {
							@apply w-7 h-7;
							stroke: #1b935b;
							fill: #1b935b;
						}
					}
				}

				.social__nav {
					@apply fixed bottom-0 left-0 w-full h-14 bg-white flex items-center justify-evenly z-[10000000] gap-4;

					a {
						@apply relative flex flex-col items-center gap-0.5 rounded-md shadow-lg p-2 overflow-hidden;

						.notification__count {
							@apply absolute top-0 right-0 p-1 min-w-[1rem] bg-rose-500 text-white text-[10px] align-middle flex items-center justify-center rounded-full;
							font-weight: 500;
							line-height: 1;
						}

						img {
							@apply w-7;
						}
					}
				}
			}
		}
	}
}
