@tailwind components;
@tailwind utilities;

@layer components {
	.telMedicine {
		@apply px-5 w-full bg-emerald-50;

		.top {
			@apply my-5 w-full max-w-7xl mx-auto bg-white/50 backdrop-blur-sm rounded-lg;

			img {
				@apply w-full h-full mx-auto;
			}
		}

		.tel__container {
			@apply w-full max-w-5xl mx-auto flex flex-col gap-4 sm:gap-20 py-5 sm:py-10;

			.bottom {
				@apply grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-20;

				.card {
					@apply flex flex-col gap-4 items-center cursor-pointer;

					.cover-img {
						@apply p-4 sm:p-5 bg-white/50 backdrop-blur-sm rounded-lg shadow border-2 border-emerald-500;
					}

					p {
						@apply inline-block px-2 py-1 border-2 border-emerald-500 text-lg font-semibold sm:text-xl text-gray-700 text-center;
					}
				}
			}
		}
	}
}
