@tailwind components;
@tailwind utilities;

@layer components {
	.footer1 {
		@apply w-full relative mt-20 flex flex-col;
		background: linear-gradient(180deg, #14fea0 0%, #15e584 100%);

		.footer1_content {
			@apply px-5 sm:px-0 py-14 w-full max-w-5xl mx-auto text-center sm:text-left;

			img {
				@apply w-64 mt-8;
			}

			h1 {
				@apply text-[#117446] text-4xl font-bold;
			}
			p {
				@apply text-[#117446] text-sm pt-4;
			}
		}
		.app_layout {
			@apply w-full max-w-[500px] sm:absolute left-[750px] top-8 pb-10 sm:pb-0;
		}
	}
	.footer2 {
		@apply w-full text-center pt-10 pb-6 sm:pt-44;

		.privacy_policy {
			button {
				@apply text-[#15e584] px-5 border-r-2 border-[#15e584] text-sm sm:text-base;
			}

			& > a:last-of-type {
				button {
					@apply border-0;
				}
			}
		}
		.social {
			@apply text-2xl p-3 hover:bg-[#b5e6d0] hover:rounded-full;

			&:nth-child(6),
			&:nth-child(7) {
				@apply text-lg;
			}

			&:last-of-type {
				@apply p-2;
			}
		}

		.social_tab {
			* {
				svg {
					@apply text-[#117446] stroke-[#117446] fill-[#117446] box-border;
				}

				.globe {
					stroke: transparent !important;
				}
			}
		}

		.footer_bottom {
			@apply w-full max-w-6xl mx-auto px-5 sm:px-0 flex justify-between items-center;
			.footer_bottom_content {
				@apply flex items-center gap-0 sm:gap-4;
				img {
					@apply w-8 sm:w-14;
				}
			}
			.footer_copyright {
				@apply text-[#22b573] text-sm sm:text-base;
			}
			.up_btn {
				@apply font-bold rounded-full bg-[#22b573] text-white px-2 py-2;
				.icon {
					@apply text-2xl;
					stroke: #fff;
				}
			}
		}
	}
}
