@tailwind components;
@tailwind utilities;

@layer components {
	.admin__layout {
		@apply w-full min-h-screen flex;

		.sidebar {
			@apply w-64 h-screen border-r flex flex-col bg-white;

			.sidebar__logo {
				@apply flex items-center justify-center py-4;

				img {
					@apply w-36;
				}
			}

			.sidebar__menu {
				@apply mt-4 flex-1 flex flex-col pl-5 gap-y-2;

				.menu-item {
					@apply flex items-center px-4 py-3 rounded-l-lg;

					&.active {
						@apply bg-gray-200;
					}

					&:hover {
						@apply bg-gray-200;
					}

					.icon {
						@apply w-6 h-6 mr-4 fill-black stroke-black;
					}

					span {
						@apply text-base tracking-wide font-medium;
					}
				}
			}

			.sidebar__footer {
				@apply flex items-center justify-center p-5;

				.btn-logout {
					@apply w-full flex items-center justify-center py-3 rounded-lg bg-gray-900;

					.icon {
						@apply w-7 h-7 -mt-[1px] mr-2 fill-white stroke-white;
					}

					span {
						@apply text-lg tracking-wide text-white;
					}
				}
			}
		}

		.main {
			@apply flex-1;
		}
	}
}
