@tailwind components;
@tailwind utilities;

@layer components {
	.videoCall {
		@apply w-full max-w-7xl h-screen mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center;

		.brand {
			@apply mt-5;

			img {
				@apply w-20;
			}
		}

		.videos {
			@apply w-full h-[calc(100vh-5rem)] grid grid-cols-1 md:grid-cols-8;

			.my-video {
				@apply w-full max-w-sm rounded-lg p-4;

				video {
					@apply w-full rounded-lg aspect-video;
				}

				h3 {
					@apply text-lg font-medium text-gray-900 text-center pt-2;
				}
			}

			.user-video {
				@apply w-full rounded-lg p-4 col-span-7;

				video {
					@apply aspect-video h-[calc(100%-3rem)] mx-auto rounded-lg;
				}

				h3 {
					@apply text-lg font-medium text-gray-900 text-center pt-2;
				}
			}

			&:not(:has(.user-video)) {
				@apply w-full flex flex-col items-center;

				.my-video {
					@apply col-span-1;
				}
			}
		}

		.incoming-call {
			@apply py-5 flex flex-col items-center;

			h1 {
				@apply mb-3 text-2xl font-medium text-gray-900 text-center pt-2;
			}

			.btn-answer {
				@apply px-4 py-2 bg-emerald-400 text-white rounded-lg shadow-lg hover:bg-emerald-500;
			}
		}

		.btn-call {
			@apply px-4 py-2 bg-emerald-400 text-white rounded-lg shadow-lg hover:bg-emerald-500;
		}

		.btn-endCall {
			@apply w-10 h-10 rounded-full flex items-center bg-rose-500 justify-center shadow-lg border border-slate-300 hover:border-slate-400;

			.icon {
				@apply w-6 h-6 text-white;
			}
		}

		.btn-btns {
			@apply w-10 h-10 rounded-full flex items-center justify-center shadow-lg border border-slate-300 hover:border-slate-400;

			.icon {
				@apply w-6 h-6;
			}
		}
	}
}
