@tailwind components;
@tailwind utilities;

@layer components {
    .meditation {
        @apply text-center pt-52;
        h1 {
            @apply text-4xl font-bold text-emerald-600;
        }
    }
}
