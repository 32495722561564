@tailwind components;
@tailwind utilities;

@layer components {
	.signIn {
		@apply w-full min-h-screen bg-[#F1FFF8] px-5 flex items-center justify-center;

		.container {
			@apply w-full max-w-lg sm:pt-0;

			.user__type {
				@apply flex items-center flex-col justify-center gap-4;

				button {
					@apply relative w-full py-4 px-6 bg-white text-emerald-500 rounded-lg text-xl shadow-lg;

					.icon {
						@apply absolute left-5 top-1/2 bottom-1/2 transform -translate-y-1/2 text-3xl duration-200 fill-emerald-500;
					}
				}
			}

			.user__form {
				@apply bg-white rounded-lg px-8 pt-6 pb-7 mt-10;
				box-shadow: 0 3px 20px rgba(0, 0, 0, 0.08);

				.top {
					@apply relative w-full;

					h1 {
						@apply mt-0 text-3xl font-bold text-gray-500;
					}

					p {
						@apply text-gray-400 mt-1;
					}

					.btn-close {
						@apply absolute -top-1 -right-2 text-3xl flex items-center justify-center text-gray-400 duration-200;

						&:hover {
							@apply text-gray-500;
						}
					}
				}

				.body {
					@apply mt-4;

					.form__group {
						@apply mb-5 bg-gray-100 rounded-md shadow;

						input {
							@apply py-3 px-5 w-full appearance-none border-none ring-0 bg-transparent placeholder:text-slate-500;

							&::-webkit-inner-spin-button,
							&::-webkit-outer-spin-button {
								-webkit-appearance: none;
								margin: 0;
							}
						}

						&.pass {
							@apply flex items-center;

							& > input {
								@apply w-full;
							}

							.icon {
								@apply mr-5 text-[1.8rem] leading-[2.5rem] text-slate-500 cursor-pointer;
							}
						}
					}

					.error {
						@apply text-rose-500 mb-5 text-center;
					}

					.btn-login {
						@apply w-full py-3 px-5 bg-emerald-500 text-white rounded-md text-xl font-bold duration-200;

						&:hover {
							@apply bg-emerald-500/90;
						}
					}
				}
			}

			.create {
				@apply mt-5 text-center text-gray-400 text-lg;

				a {
					@apply text-emerald-500;
				}
			}
		}
	}
}
