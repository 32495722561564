@tailwind components;
@tailwind utilities;

@layer components {
	.contact {
		@apply h-[500px] w-full relative;
		background-attachment: fixed;

		.contact_content {
			.contact_nav {
				@apply flex justify-between items-center px-48 py-5;

				img {
					@apply w-52;
				}
			}
			.contact_title {
				@apply text-center text-white text-4xl font-bold pt-8;
			}
			.contact_details {
				@apply w-[900px] lg:mx-[300px] mx-[20px] py-8 px-5 pb-20 bg-white absolute top-[300px] rounded-2xl;
				p {
					@apply py-2 text-xl;
				}
				a {
					color: #22b573;
				}
				span {
					color: #22b573;
				}
			}
		}
	}
}
