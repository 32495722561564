@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		@apply box-border font-poppins;
	}

	body {
		@apply overflow-auto;
	}

	body:has(.navbar > .navbar__modal),
	body:has(.feed > .createPostPopup),
	body:has(.ePrescription .customTestModal.active) {
		@apply overflow-hidden;
	}

	body:has(.navbar .nav__container2 .social__nav) {
		@apply pb-12;
	}

	::-webkit-scrollbar {
		@apply w-2;
	}

	::-webkit-scrollbar-track {
		@apply bg-gray-200 rounded;
	}

	::-webkit-scrollbar-thumb {
		@apply bg-gray-400 rounded;
	}
}

@layer components {
	.page {
		min-height: calc(100vh - 5rem);
	}
}
