@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.secure__container {
		.secure__flex {
			@apply flex justify-center flex-col lg:flex-row mx-8 lg:mx-[400px]  items-center pt-32 gap-10;
			.secure__content {
				h1 {
					@apply text-4xl text-emerald-500 font-bold;
				}
			}
			.secure__img {
				img {
					@apply w-[500px] rounded-lg;
				}
			}
		}
	}
}
