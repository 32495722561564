@import url('https://fonts.googleapis.com/css2?family=Mooli&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@tailwind components;
@tailwind utilities;

@layer components {
	.home {
		@apply relative;
		background-color: #ffffff;

		.header__section {
			@apply relative h-[41rem] sm:h-[41rem] w-full;

			background: linear-gradient(
				180deg,
				#f1fbf7 0%,
				#bcf6db 40%,
				#b1e7cd 70%,
				#d3f7e6 90%,
				#ffffff 100%
			);

			& > img {
				@apply absolute bottom-0 right-10 sm:right-52 w-full max-w-[300px] sm:max-w-lg;
			}

			.header__container {
				@apply w-full max-w-[68rem] mx-auto px-5 lg:px-8 py-10 grid grid-cols-1 sm:grid-cols-2 gap-x-12;

				.left {
					@apply w-full sm:mt-24;

					h4 {
						@apply w-full flex items-center gap-x-4 gap-y-2;

						img {
							@apply w-10 sm:w-16;
						}

						p {
							@apply text-left;

							span {
								@apply text-xs sm:text-base text-[#117446] font-semibold;

								&:first-child {
									@apply inline sm:block mr-1 sm:mr-0;
								}
							}
						}
					}

					h2 {
						@apply mt-10 sm:mt-16 text-[#117446] text-xl sm:text-2xl font-bold;
					}

					h1 {
						@apply mt-1 flex items-end gap-2 sm:gap-3;

						span {
							&:first-child {
								@apply text-[#117446] text-xl sm:text-3xl font-bold;
							}

							&:last-child {
								@apply text-[#117446] text-xl sm:text-3xl font-bold;
							}
						}
					}

					.action {
						@apply w-full flex items-center gap-4 mt-4;

						.btn-access {
							@apply px-4 py-2 rounded-xl text-sm sm:text-base text-[#117446] font-semibold bg-white;

							background: linear-gradient(180deg, #14ffa1 0%, #15e685 100%);
						}

						p {
							@apply text-[#117446] text-base font-semibold;
						}

						span {
							@apply text-[#117446] text-xs font-medium tracking-wide border-l-2 border-l-[#58b389] pl-4 py-1;
						}
					}
				}
			}
		}

		.service__section {
			@apply w-full max-w-[70rem] mx-auto px-6 lg:px-8 pt-20 flex flex-col-reverse sm:flex-row items-center justify-center gap-10;

			.left {
				@apply flex-[35%] w-full;

				.users {
					@apply relative w-full flex flex-col items-center justify-center;

					h1 {
						@apply relative px-0 sm:px-10 py-6 w-full flex flex-col items-center sm:items-start justify-center rounded-[2rem] z-[3] gap-y-1;

						background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

						p {
							@apply text-[#117446] text-2xl font-bold;
						}

						span {
							@apply text-[#117446] text-[0.82rem] leading-5 font-semibold text-center sm:text-left;
						}
					}

					img {
						@apply absolute top-[4.4rem] z-[2] w-60;
					}
				}
			}

			.right {
				@apply flex-[65%] relative;

				.xx__img {
					@apply absolute bottom-full right-0;

					img {
						@apply w-full max-w-lg;
					}
				}

				.services {
					@apply py-5 sm:py-10 px-6 sm:px-16 grid grid-cols-1 sm:grid-cols-2 border-2 border-[#2c9263] rounded-3xl gap-x-6 gap-y-8;

					.service {
						@apply w-full flex flex-col gap-2;

						.top {
							@apply w-full flex items-center gap-3;

							img {
								@apply w-12;
							}

							h3 {
								@apply text-[#117446] font-semibold text-[1.2rem] leading-7;
							}
						}

						p {
							@apply text-[0.80rem] text-[#117446] leading-5;
						}
					}
				}
			}
		}

		.explore__section {
			@apply w-full max-w-[68rem] mx-auto px-5 lg:px-8 pt-28 flex flex-col sm:flex-row items-center justify-center gap-x-24 gap-y-14;

			.left {
				@apply w-full flex flex-col;

				.top {
					@apply w-full px-5 sm:px-0;

					p {
						@apply text-[#117446] text-base sm:text-2xl font-bold;
					}

					h2 {
						@apply mt-1 sm:mt-2 flex items-end gap-6;

						span {
							&:first-child {
								@apply text-xl sm:text-5xl text-[#15e584] font-bold;
							}

							&:last-child {
								@apply -top-[0.15rem] relative text-base sm:text-[1.75rem] sm:leading-10 text-[#63a486] font-bold;

								&::before {
									@apply content-[''] absolute top-[calc(50%+0.04rem)] -left-[0.6rem] w-[calc(100%+1.3rem)] h-[0.13rem] sm:h-[0.18rem] bg-[#63a486] rounded-full;
								}
							}
						}
					}
				}

				.bottom {
					@apply relative w-full h-full sm:h-[26rem] flex items-center justify-center mt-4;
					background-image: url(https://i.ibb.co/5jmjznr/world.png);
					background-size: contain;
					background-repeat: no-repeat;
					background-position: top center;

					.globe__blur {
						@apply w-full bg-white/50 px-5 sm:px-10 pb-5 pt-20 sm:pb-8 sm:pt-8 rounded-3xl flex items-start;

						// backdrop-filter: blur(10px);

						span {
							&:first-child {
								@apply -mt-[1.8rem] text-[4rem] text-[#117446] hidden sm:block;
								font-family: 'Engravers MT';
							}

							&:last-child {
								@apply text-[0.8rem] text-[#117446] leading-5;
							}
						}
					}
				}
			}

			.right {
				@apply w-full max-w-[17rem] grid grid-cols-1 gap-y-11;

				.explore__card {
					@apply w-full flex flex-col items-center justify-center;

					.cover {
						@apply rounded-[2rem] w-full;

						background: linear-gradient(
							180deg,
							#cdfae5 0%,
							#cdfae5 50%,
							#65fac2 100%
						);
					}

					button {
						@apply w-full max-w-[80%] py-3 text-[#117446] rounded-b-[2rem] uppercase font-bold;

						background: linear-gradient(180deg, #15f293 0%, #15e584 100%);
					}
				}
			}
		}

		.cloud__section {
			@apply w-full max-w-5xl mx-auto px-6 lg:px-8 pt-28;

			.section__header {
				h1 {
					@apply flex items-center justify-start sm:justify-center gap-4;

					img {
						@apply w-7 sm:w-9;
					}

					span {
						@apply text-3xl sm:text-4xl text-[#15e584] font-bold;
					}
				}

				p {
					@apply sm:text-center text-lg sm:text-2xl text-[#117446] font-bold my-2;
				}
			}

			.clouds {
				@apply w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 mt-6 sm:mt-10;

				.cloud {
					@apply rounded-[2rem] flex flex-col;

					box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
					background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

					.block__icon {
						@apply w-full pt-4 h-40 sm:h-[12.5rem] flex items-center justify-center;

						img {
							@apply w-20 sm:w-24;
						}
					}

					&:nth-child(2) {
						.block__icon {
							img {
								@apply w-16 sm:w-20;
							}
						}
					}

					.block__content {
						@apply px-7 pb-12;

						h3 {
							@apply text-[1.3rem] leading-7 sm:text-lg text-[#117446] font-semibold;

							span {
								@apply text-2xl sm:text-xl font-bold;
							}
						}

						p {
							@apply mt-4 sm:mt-2 text-[#117446] text-base sm:text-[0.8rem] leading-[1.25rem] text-justify sm:text-left;

							span {
								@apply text-[#117446] font-semibold cursor-pointer hover:underline underline-offset-2;
							}
						}
					}
				}
			}
		}

		.content__section {
			@apply w-full bg-white;

			.content__container {
				@apply w-full max-w-5xl mx-auto px-6 lg:px-8 pt-28;

				.content__card {
					@apply w-full flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-8;

					&:nth-child(odd) {
						@apply sm:flex-row-reverse;
					}

					&:not(:last-child) {
						@apply mb-6;
					}

					.content__card__img {
						@apply w-full max-w-xs;

						img {
							@apply w-full;
						}
					}

					.content__card__content {
						@apply w-auto sm:w-1/2;

						h3 {
							@apply text-2xl font-semibold text-emerald-600 mb-4;
						}

						p {
							@apply flex flex-col gap-1;

							.sub-title-desc {
								@apply text-base font-medium text-gray-500;
							}

							.title-desc {
								@apply text-sm text-justify text-gray-500 mb-4;
							}
						}

						a {
							@apply flex items-center gap-1 text-base text-emerald-600 font-semibold hover:underline;

							.icon {
								@apply text-xl;
							}
						}
					}
				}
			}
		}

		.slider__section {
			@apply w-full max-w-5xl mx-auto px-6 lg:px-8 pt-28;

			.section__header {
				h1 {
					@apply flex items-center justify-start sm:justify-center gap-4;

					img {
						@apply w-6 sm:w-9;
					}

					span {
						@apply text-2xl sm:text-4xl text-[#15e584] font-bold;
					}
				}

				p {
					@apply sm:text-center text-lg sm:text-2xl text-[#117446] font-bold my-2;
				}
			}

			.slider {
				@apply w-full grid grid-cols-1 md:grid-cols-2 mt-6 sm:mt-14 items-center gap-y-5 sm:gap-y-0;

				.left {
					@apply w-full sm:pl-10;

					img {
						@apply w-full max-w-xs mr-auto;
					}
				}

				.right {
					@apply w-full flex flex-col;

					.slider__content {
						@apply w-full;

						h3 {
							@apply text-xl sm:text-2xl text-[#117446] font-bold;
						}

						h2 {
							@apply text-3xl sm:text-5xl font-bold text-[#15e584] mt-2;
						}

						p {
							@apply mt-5 sm:mt-8 text-[0.83rem] text-[#117446] leading-[1.25rem] text-justify sm:text-left;
						}
					}

					.slider__controls {
						@apply w-full flex items-center justify-between mt-6 sm:mt-10;

						.left__side {
							@apply flex items-center gap-5;

							button {
								@apply w-10 h-10 flex items-center justify-center rounded-full;

								background: linear-gradient(90deg, #15e584 0%, #14ffa1 100%);

								.icon {
									@apply text-xl fill-white;
								}

								&:last-child {
									background: linear-gradient(90deg, #14ffa1 0%, #15e584 100%);
								}
							}
						}

						.right__side {
							@apply flex items-center gap-5;

							button {
								@apply w-10 h-2 bg-[#59a481] rounded-full;

								&.active {
									background: linear-gradient(180deg, #14fea0 0%, #15e786 100%);
								}
							}
						}
					}
				}
			}
		}

		.blogs__section {
			@apply w-full max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-28;

			.section__header {
				h1 {
					@apply flex items-center justify-start sm:justify-center gap-4;

					img {
						@apply w-7 sm:w-9;
					}

					span {
						@apply text-3xl sm:text-4xl text-[#15e584] font-bold;
					}
				}

				p {
					@apply sm:text-center text-lg sm:text-2xl text-[#117446] font-bold my-2;
				}
			}

			.blogs {
				@apply w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-10 mt-6 sm:mt-10;

				.blog {
					@apply w-full flex flex-col;

					.cover {
						@apply w-full overflow-hidden border-4 border-white rounded-[2rem] mb-5;
						box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);

						img {
							@apply w-full h-full object-cover;
						}
					}

					.text {
						@apply text-sm text-[#117446] px-8 sm:px-5 text-justify;

						.btn-readMore {
							@apply font-semibold text-[#117446] cursor-pointer hover:underline underline-offset-2;
						}
					}
				}
			}
		}

		.booking__section {
			@apply w-full max-w-5xl mx-auto px-6 lg:px-8 pt-28 pb-20;

			.box__container {
				@apply w-full max-w-2xl mx-auto flex flex-col items-center justify-center;

				.box {
					@apply w-full flex flex-col items-center justify-center gap-5 px-5 sm:px-10 pb-5 sm:pb-8 sm:rounded-[2rem] rounded-2xl;

					background: linear-gradient(
						180deg,
						#fbfbfb 0%,
						#fbfbfb 30%,
						#51f8aa 100%
					);

					img {
						@apply w-20;
					}

					h1 {
						@apply text-center sm:text-left text-xl sm:text-2xl text-[#117446] font-bold;
					}

					p {
						@apply text-justify text-[#117446] text-sm sm:text-center;

						span {
							@apply text-center block mt-2 text-[#117446] font-semibold;
						}
					}
				}

				button {
					@apply bg-[#15e685] px-7 sm:px-14 py-3 sm:py-4 font-bold text-[#117446] text-base sm:text-lg sm:rounded-b-[2rem] rounded-b-2xl;
				}
			}
		}

		.anim__section {
			.anim__container {
				@apply w-full bg-white;

				@apply w-full max-w-5xl mx-auto flex flex-col sm:flex-row gap-5 px-6 lg:px-8 py-10;

				.box__container {
					@apply flex-shrink-0 w-full max-w-2xl mx-auto flex flex-col items-center justify-center;

					svg {
						.cls-1 {
							fill: #333;
						}
						.cls-2 {
							clip-path: url(#clip-path);
						}
						.cls-3 {
							fill: #4d4d4d;
						}

						.cls-11,
						.cls-12,
						.cls-14,
						.cls-15,
						.cls-16,
						.cls-17,
						.cls-18,
						.cls-20,
						.cls-22,
						.cls-4,
						.cls-5,
						.cls-7,
						.cls-8 {
							fill: none;
						}

						.cls-4 {
							opacity: 0.47;
						}
						.cls-22,
						.cls-5 {
							stroke: #b3b3b3;
						}

						.cls-12,
						.cls-14,
						.cls-17,
						.cls-18,
						.cls-20,
						.cls-5 {
							stroke-linejoin: round;
						}

						.cls-5 {
							stroke-width: 12.377px;
						}
						.cls-6 {
							clip-path: url(#clip-path-2);
						}
						.cls-11,
						.cls-7 {
							stroke: #4d4d4d;
						}
						.cls-11,
						.cls-15,
						.cls-16,
						.cls-22,
						.cls-7 {
							stroke-miterlimit: 10;
						}
						.cls-16,
						.cls-20,
						.cls-7 {
							stroke-width: 4px;
						}
						.cls-9 {
							fill: #15e584;
						}
						.cls-10 {
							clip-path: url(#clip-path-3);
						}
						.cls-11,
						.cls-12,
						.cls-18 {
							stroke-width: 6px;
						}
						.cls-12,
						.cls-14,
						.cls-16,
						.cls-17,
						.cls-18,
						.cls-20 {
							stroke: #15e584;
						}
						.cls-13 {
							clip-path: url(#clip-path-4);
						}
						.cls-14 {
							stroke-width: 6.346px;
						}
						.cls-15 {
							stroke: #ccc;
							stroke-width: 7px;
						}
						.cls-17 {
							stroke-width: 8px;
						}
						.cls-18 {
							fill-rule: evenodd;
						}
						.cls-19 {
							fill: #fff;
						}
						.cls-21 {
							fill: #e1f9ec;
						}
						.cls-22 {
							stroke-width: 23px;
						}
						.cls-23 {
							fill: #b3b3b3;
						}

						#chip {
							fill: #15e584;
						}
						.chip-txt {
							fill: #fff;
						}

						// <!-- Circle-1 Animation -->
						#dash-circle-2 {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle1-1 100ms linear infinite;
						}

						@keyframes circle1-1 {
							to {
								transform: rotate(6deg);
							}
						}

						#leaf {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-15deg);
							animation: circle1-2 1s ease-in-out alternate infinite;
						}

						@keyframes circle1-2 {
							to {
								transform: rotate(25deg);
							}
						}

						#big-dash-3 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-90deg);
							animation: circle1-3 2s ease alternate infinite;
						}
						#dash {
							stroke: #fff;
							stroke-width: 10px;
						}

						@keyframes circle1-3 {
							to {
								transform: rotate(150deg);
							}
						}

						#bold-dash {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle1-4 2s ease infinite;
						}

						@keyframes circle1-4 {
							to {
								transform: rotate(360deg);
							}
						}

						#dash-circle-3 {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle1-5 1s linear alternate infinite;
						}

						@keyframes circle1-5 {
							to {
								transform: rotate(90deg);
							}
						}

						#dot-circle-4 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(45deg);
							animation: circle1-6 2s linear alternate infinite;
						}

						@keyframes circle1-6 {
							to {
								transform: rotate(-90deg);
							}
						}

						#circle-0 {
							transform: scale(0.9);
							transform-box: fill-box;
							transform-origin: center;

							animation: circle1-7 1s ease alternate infinite;
						}

						@keyframes circle1-7 {
							to {
								transform: scale(1.1);
							}
						}
						// <!-- Circle-2 Animation -->
						#dot-circle-3 {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle2-1 7s linear infinite;
						}

						@keyframes circle2-1 {
							to {
								transform: rotate(180deg);
							}
						}

						#small-dash {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle2-2 1s linear alternate infinite;
						}

						@keyframes circle2-2 {
							to {
								transform: rotate(25deg);
							}
						}

						#big-dash-2 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(25deg);
							animation: circle2-3 1s linear alternate infinite;
						}

						@keyframes circle2-3 {
							to {
								transform: rotate(-10deg);
							}
						}

						#ring-2-dot {
							transform-box: fill-box;
							transform-origin: center;
							transform: translateX(4px) translateY(0px);
							animation: circle2-4 200ms linear infinite;
						}

						@keyframes circle2-4 {
							to {
								transform: translateX(-4px) translateY(4px);
							}
						}

						#ring-2 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-10deg);
							animation: circle2-5 1s ease 200ms alternate infinite;
						}

						@keyframes circle2-5 {
							to {
								transform: rotate(25deg);
							}
						}

						// <!-- Circle-3 Animation -->
						#dash-circle {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle3-1 500ms linear infinite;
						}

						@keyframes circle3-1 {
							to {
								transform: rotate(6deg);
							}
						}

						#dot-circle-2 {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle3-2 500ms linear infinite;
						}

						@keyframes circle3-2 {
							to {
								transform: rotate(4deg);
							}
						}

						#ring-3-dot {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle3-3 200ms linear infinite;
							transform: translateX(-4px) translateY(-4px);
						}

						@keyframes circle3-3 {
							to {
								transform: translateX(4px) translateY(4px);
							}
						}

						#ring-3 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-20deg);
							animation: circle3-4 1000ms ease alternate infinite;
						}

						@keyframes circle3-4 {
							to {
								transform: rotate(25deg);
							}
						}

						// <!-- Circle-4 Animation -->
						#dot-circle {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle4-1 7s linear infinite;
						}

						@keyframes circle4-1 {
							to {
								transform: rotate(180deg);
							}
						}

						#big-dash {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-25deg);
							animation: circle4-2 1s ease alternate infinite;
						}

						@keyframes circle4-2 {
							to {
								transform: rotate(25deg);
							}
						}

						#ring-4-dot {
							transform-box: fill-box;
							transform-origin: center;
							animation: circle4-3 200ms linear infinite;
							transform: translateX(4px) translateY(0px);
						}

						@keyframes circle4-3 {
							to {
								transform: translateX(-4px) translateY(8px);
							}
						}

						#ring-4 {
							transform-box: fill-box;
							transform-origin: center;
							transform: rotate(-25deg);
							animation: circle4-4 1s ease alternate infinite;
						}

						@keyframes circle4-4 {
							to {
								transform: rotate(0deg);
							}
						}
					}
				}

				.text__container {
					@apply flex items-center justify-center text-center w-full border-2 border-emerald-100 rounded-lg p-5 text-[#117446] font-bold;

					font-family: 'Mooli', sans-serif;
				}
			}
		}
	}
}
