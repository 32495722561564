@tailwind components;
@tailwind utilities;

@layer components {
	.about {
		@apply h-[500px] w-full relative;
		background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('../../../public/footer-content/about.jpeg') no-repeat;
		background-position: center;
		background-size: cover;

		.content {
			.about_nav {
				@apply flex justify-between items-center px-48 py-5;

				img {
					@apply w-52;
				}
			}
			.about_title {
				@apply text-center text-white text-4xl font-bold pt-8;
			}
			.details {
				@apply lg:mx-[300px] mx-[20px] py-8 px-5 bg-white absolute top-[300px] rounded-2xl;
				p {
					@apply py-2;
				}
				h2 {
					@apply text-2xl font-bold py-2;
				}
			}
		}
	}
}
