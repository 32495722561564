@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.specialistRoute {
		@apply w-full mx-auto bg-emerald-50 py-8 px-4;

		.content__area {
			@apply relative w-full max-w-7xl mx-auto mb-20 flex sm:gap-x-10;

			.left__side {
				@apply flex-shrink-0 bg-white w-full max-w-[340px] hidden sm:flex flex-col gap-5 rounded-md border overflow-hidden;

				& > h1 {
					@apply bg-[#33FD9B] text-[#117446] py-6 text-center text-xl font-medium mb-5;
				}

				.items {
					@apply p-5 flex flex-col gap-5;

					.parent__item {
						@apply px-4 py-2 border border-slate-400 rounded-md cursor-pointer;

						.sub__item {
							@apply ml-5 text-sm pb-2;

							&:nth-child(2) {
								@apply mt-2;
							}
						}
					}
				}
			}

			.right__side {
				.all__items {
					@apply grid grid-cols-1 sm:grid-cols-3 gap-10;

					.single__item {
						@apply max-h-32 rounded-lg overflow-hidden relative cursor-pointer;

						img {
							@apply w-full h-full object-cover;
						}

						p {
							@apply absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-50 text-sm text-white flex items-end p-2 font-medium;
						}

						&:hover {
							p {
								@apply h-fit;
							}
						}
					}
				}
			}
		}

		// .specialist_card {
		// 	@apply w-full max-w-6xl mx-auto grid sm:grid-cols-4 grid-cols-1 gap-5 my-10;
		// 	justify-items: center;

		// 	.specialist_card_content {
		// 		@apply flex px-4 py-1 items-center gap-5 border shadow rounded-xl bg-white w-[250px] transition-all duration-300 ease-in-out transform hover:scale-110;

		// 		img {
		// 			@apply w-16;
		// 		}
		// 	}
		// }
	}
}
