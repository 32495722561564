@tailwind components;
@tailwind utilities;

@layer components {
	.presPreview {
		@apply w-full flex justify-center bg-emerald-50 py-10;

		.box {
			@apply relative w-[64rem] mx-auto flex flex-col bg-white rounded-2xl shadow-lg overflow-hidden pb-20;

			.btn-print {
				@apply absolute bottom-8 right-10 flex items-center gap-2 px-4 py-2 rounded-2xl;

				background: linear-gradient(180deg, #14ffa1 0%, #15e584 100%);

				.icon {
					@apply fill-[#117446] w-8 h-8;
				}

				span {
					@apply text-[#117446] text-xl uppercase font-bold;
				}
			}
		}
	}

	#innerBo {
		@apply bg-white p-16 flex-grow;

		.sec__one {
			@apply w-full flex flex-col;

			.first {
				@apply w-full flex items-center justify-between;

				.romatooLogo {
					@apply w-40;

					img {
						@apply w-full;
					}
				}

				.doctorName {
					@apply text-[#117446] text-base text-right space-y-2;
				}
			}

			.second {
				@apply relative w-full mt-4 ml-4 flex items-start justify-between;

				p {
					@apply text-[#117446] text-base;
				}

				img {
					@apply absolute top-0 right-4 w-28;
				}
			}
		}

		.ePres {
			@apply text-[#117446] text-4xl mt-6 mb-3;
		}

		.sec__two {
			@apply w-full flex flex-col;

			.first,
			.second {
				@apply flex items-center justify-between text-[#117446] text-base space-y-2;
			}
		}

		.warn {
			@apply flex items-center gap-2 mt-2;

			.icon {
				@apply fill-[#117446] w-8 h-8;
			}

			span {
				@apply text-[#117446] text-base;
			}
		}

		.sec__three {
			@apply w-full mt-10;

			& > hr {
				@apply my-4 border-[#2c9263] border-t-2;
			}

			table {
				@apply w-full text-[#117446] text-base;

				thead {
					@apply text-[#117446] text-base;

					tr {
						@apply text-[#117446] text-base;

						th {
							@apply text-[#117446] text-base font-medium text-left;

							&:last-child {
								@apply text-right;
							}
						}
					}
				}

				tbody {
					@apply text-[#117446] text-base;

					tr {
						@apply text-[#117446] text-base;

						td {
							@apply text-[#117446] text-base py-2;

							&:nth-child(3) {
								@apply grid grid-cols-5 gap-1;
							}

							&:last-child {
								@apply text-right;
							}
						}

						&:not(:last-child) {
							& > td {
								@apply border-[#2c9263] border-b border-dashed;
							}
						}
					}
				}
			}
		}
	}
}
