@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.details__concept {
		@apply flex justify-center items-center flex-col lg:flex-row rounded-xl m-16 space-x-5 shadow-xl mx-5 lg:mx-16;
		img {
			@apply w-[400px] rounded-lg;
		}
		.concept__container {
			@apply space-y-8 p-5;
			h1 {
				@apply text-4xl font-bold text-emerald-500;
			}
		}
	}
}
