@tailwind components;
@tailwind utilities;

@layer components {
	.videoCallPage {
		@apply w-full h-screen mx-auto p-4 sm:p-6 flex flex-col items-center;

		.videos {
			@apply flex-grow w-full max-w-7xl mx-auto;

			&:not(:has(.user-video)) {
				@apply flex flex-col items-center;

				.my-video {
					@apply w-full flex flex-col items-center gap-2;
				}
			}

			&:has(.user-video) {
				@apply grid grid-cols-1 md:grid-cols-8 gap-4;

				.user-video {
					@apply col-span-6 w-full flex flex-col items-center gap-2;

					video {
						@apply w-auto h-full;
					}
				}

				.my-video {
					@apply col-span-2 w-full flex flex-col items-center gap-2;
				}
			}
		}

		.buttons {
			@apply mt-4 flex-shrink-0 w-full flex items-center justify-center gap-4;

			.btn-all {
				@apply w-10 h-10 rounded-full flex items-center justify-center shadow-lg border border-slate-300 hover:border-slate-400;

				.icon {
					@apply w-6 h-6;
				}

				&.toggle {
					@apply bg-rose-500;

					.icon {
						@apply text-white;
					}
				}
			}

			.btn-endCall {
				@apply w-10 h-10 rounded-full flex items-center bg-rose-500 justify-center shadow-lg border border-slate-300 hover:border-slate-400;

				.icon {
					@apply w-6 h-6 text-white;
				}
			}
		}

		.prescription-popup {
			@apply hidden fixed top-0 left-0 w-full h-screen bg-black/10 z-50 overflow-y-auto;

			& > .btn-close {
				@apply fixed top-0 right-0 w-14 h-14 flex items-center justify-center text-2xl text-slate-600 hover:text-slate-700;
			}

			&.active {
				@apply block;
			}
		}
	}
}
