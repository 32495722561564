@tailwind components;
@tailwind utilities;

@layer components {
	.upcoming__appointments {
		@apply w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-20 sm:mt-0;

		& > h1 {
			@apply text-2xl font-bold text-slate-600 text-center my-4;
		}

		& > .loading {
			@apply flex justify-center items-center;

			svg {
				@apply w-20 h-20;
			}
		}

		.appointments__list {
			@apply w-full overflow-auto;

			& > .noUpcoming {
				@apply text-center text-slate-600 text-xl font-bold my-4;
			}

			.upcoming__appointment__table {
				@apply w-full border-collapse border border-slate-200 overflow-y-scroll table-fixed;

				& > thead {
					@apply bg-slate-100;

					& > tr {
						& > th {
							@apply text-slate-600 text-center py-3 px-4;

							&:first-child {
								@apply text-left;
							}
						}
					}
				}

				& > tbody {
					& > tr {
						& > td {
							@apply text-slate-600 text-center py-3 px-4;

							&:first-child {
								@apply text-left;
							}

							&.actions {
								@apply grid grid-cols-2 gap-2;

								.btn-accept {
									@apply bg-emerald-500 text-white font-medium tracking-wide py-2 px-4 rounded text-sm;

									&:disabled {
										@apply bg-emerald-300 cursor-not-allowed;
									}
								}

								.btn-reject {
									@apply bg-rose-500 text-white font-medium tracking-wide py-2 px-4 rounded text-sm;

									&:disabled {
										@apply bg-rose-300 cursor-not-allowed;
									}
								}

								.btn-complete {
									@apply bg-yellow-500 text-white font-medium tracking-wide py-2 px-4 rounded text-sm;

									&:disabled {
										@apply bg-orange-300/90 cursor-not-allowed;
									}
								}

								.btn-cancel {
									@apply bg-slate-400 text-white font-medium tracking-wide py-2 px-4 rounded text-sm;

									&:disabled {
										@apply bg-slate-300 cursor-not-allowed;
									}
								}

								&:not(:has(:nth-child(2))) {
									@apply grid-cols-1;
								}

								svg {
									@apply w-4 h-4;
								}
							}
						}

						&:nth-child(even) {
							@apply bg-slate-50;
						}
					}
				}
			}
		}
	}
}
